import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup,FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { DateField } from '../date-field';
import { moveDetailedImpacts } from '../../static-data';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSlice';

export const WizardDetailedImpact: FC = () => {
  const { t } = useTranslation();

  const { detailedImpact } = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          {Object.keys(moveDetailedImpacts).map((di) => (
            <Grid item xs={4} textAlign="center">
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{
                  backgroundColor: parseInt(detailedImpact, 10) === parseInt(di, 10) ? "success.main" : "background.paper",
                  borderRadius: 1,
                  padding: 3,
                  cursor: "pointer" }}
                onClick={() => dispatch(updateField({field: 'detailedImpact', value: parseInt(di, 10)}))}
              >
                <Typography variant="subtitle1" color={parseInt(detailedImpact, 10) === parseInt(di, 10) ? "success.contrastText" : "success.main"} width="100%">
                  {t(moveDetailedImpacts[di]['label'])}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
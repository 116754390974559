import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/auth';
import { userGetInitialization } from '../api/account';
import { getLocalToken } from '../services/token.service';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any | null;
  accessRights: any;
  projects: any[];
  activeProject: any;
  welcomeMessageRead: boolean;
}

interface AuthContextValue extends State {
  method: 'JWT';
  // initialize: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  welcomeMessageReadQuery: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: any | null;
    accessRights: any;
    projects: any[];
    activeProject: any;
    welcomeMessageRead: boolean;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type WelcomeMessageReadAction = {
  type: 'WELCOMEMESSAGEREAD';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: any;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | WelcomeMessageReadAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessRights: null,
  projects: [],
  activeProject: null,
  welcomeMessageRead: false
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, accessRights, projects, activeProject, welcomeMessageRead } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessRights,
      projects,
      activeProject,
      welcomeMessageRead
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  WELCOMEMESSAGEREAD: (state: State): State => ({
    ...state,
    welcomeMessageRead: true
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  method: 'JWT',
  // initialize: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  welcomeMessageReadQuery: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = getLocalToken();
  
        if (accessToken) {
          // const user = await authApi.me(accessToken);
          const info = await userGetInitialization();
          const user = info["result"];
          const accessRights = info["active_project"]["access_rights"];
          const activeProject = info["active_project"]["project"];
          const projects = info["projects"];
          const welcomeMessageRead = user["welcome_message_read"];

          localStorage.setItem('token', info["token"]);
          localStorage.setItem('refresh_token', info["refresh_token"]);
  
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              accessRights,
              projects,
              activeProject,
              welcomeMessageRead
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              accessRights: null,
              projects: [],
              activeProject: null,
              welcomeMessageRead: false
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            accessRights: null,
            projects: [],
            activeProject: null,
            welcomeMessageRead: false
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    // const accessToken = await authApi.login({ email, password });
    // const user = await authApi.me(accessToken);

    await AuthService.api.post('/users/signin', JSON.stringify({email: email, password: password}))
      .then((response) => {
        const user = response.data["data"];
        localStorage.setItem('it_user', JSON.stringify(user));
        localStorage.setItem('token', user["token"]);
        localStorage.setItem('refresh_token', user["refresh_token"]);
        dispatch({
          type: 'LOGIN',
          payload: {
            user
          }
        });
        window.location.href = '/projects';
      })
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('it_user');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    dispatch({ type: 'LOGOUT' });
  };

  const welcomeMessageReadQuery = async (): Promise<void> => {
    // const accessToken = await authApi.login({ email, password });
    // const user = await authApi.me(accessToken);

    await ApiService.put('/users/welcome-message-read')
      .then((response) => {
        dispatch({
          type: 'WELCOMEMESSAGEREAD'
        });
      })
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        // initialize,
        login,
        logout,
        welcomeMessageReadQuery,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

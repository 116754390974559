import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  stakeholderDialogOpen: false,
  stakeholderId: null,
  stakeholderProfile: {
    name: '',
    comment: '',
    levelOfPower: 5,
    levelOfSupport: 5,
    howImpacted: '',
    benefits: '',
    collateralDamages: ''
  },
  stakeholderLevers: {
    aspirations: [],
    resistances: []
  },
  stakeholdersInfluencings: [],
  stakeholderIsLoading: false,
  stakeholderStatus: null,
  stakeholderNextAction: null,
  isEditabled: true,
  isDuplicated: false,
};

export const stakeholder = createSlice({
  name: "stakeholder",
  initialState,
  reducers: {
    openStakeholderDialog: (state, action) => {
      // Reinitialize
      state.stakeholderId = null;
      state.stakeholderStatus = null;
      state.stakeholderId = null;
      // Profile
      state.stakeholderProfile.name = '';
      state.stakeholderProfile.comment = '';
      state.stakeholderProfile.levelOfPower = -1;
      state.stakeholderProfile.levelOfSupport = -1;
      state.stakeholderProfile.howImpacted = '';
      state.stakeholderProfile.benefits = '';
      state.stakeholderProfile.collateralDamages = '';
      // Levers
      state.stakeholderLevers.aspirations = [];
      state.stakeholderLevers.resistances = [];
      // Influencings
      state.stakeholdersInfluencings = [];

      state.stakeholderDialogOpen = true;
      if (action.payload.element.id) {
        // Element
        if (action.payload.isDuplicated === true) {
          state.stakeholderId = null;
          state.stakeholderProfile.name = `${action.payload.element.name} - copy`;
        } else {
          state.stakeholderId = action.payload.element.id;
          state.stakeholderProfile.name = action.payload.element.name;
        }
        // Profile
        state.stakeholderProfile.comment = action.payload.element.comment;
        state.stakeholderProfile.levelOfPower = action.payload.element.level_of_power;
        state.stakeholderProfile.levelOfSupport = action.payload.element.level_of_support;
        state.stakeholderProfile.howImpacted = action.payload.element.how_impacted;
        state.stakeholderProfile.benefits = action.payload.element.benefits;
        state.stakeholderProfile.collateralDamages = action.payload.element.collateral_damages;
        // Levers
        state.stakeholderLevers.aspirations = action.payload.element.aspirations;
        state.stakeholderLevers.resistances = action.payload.element.resistances;
        // Influencings
        state.stakeholdersInfluencings = action.payload.element.influencings;
        // Other
        state.isEditabled = action.payload.isEditabled;
        state.isDuplicated = action.payload.isDuplicated;
      }
    },
    closeStakeholderDialog: (state) => {
      state.stakeholderDialogOpen = false;
    },
    loadingStakeholder: (state) => {
      state.stakeholderIsLoading = true;
    },
    resetStakeholder: (state, action) => {
      state.stakeholderIsLoading = false;
      state.stakeholderStatus = 'success';
      state.stakeholderNextAction = action.payload;
    },
    resetStakeholderStatus: (state) => {
      state.stakeholderId = null;
      state.stakeholderStatus = null;
      state.stakeholderId = null;
      // Profile
      state.stakeholderProfile.name = '';
      state.stakeholderProfile.comment = '';
      state.stakeholderProfile.levelOfPower = 5;
      state.stakeholderProfile.levelOfSupport = 5;
      state.stakeholderProfile.howImpacted = '';
      state.stakeholderProfile.benefits = '';
      state.stakeholderProfile.collateralDamages = '';
      // Levers
      state.stakeholderLevers.aspirations = [];
      state.stakeholderLevers.resistances = [];
      // Influencings
      state.stakeholdersInfluencings = [];
    },
    updateField: (state, action) => {
      if (action.payload.field === null) {
        state[action.payload.parentField] = action.payload.value;
      } else {
        state[action.payload.parentField][action.payload.field] = action.payload.value;
      }
    }
  },
});

export const {
  openStakeholderDialog,
  closeStakeholderDialog,
  loadingStakeholder,
  resetStakeholder,
  resetStakeholderStatus,
  updateField
} = stakeholder.actions;

export default stakeholder.reducer;

export const stakeholderPost = (nextAction, data: any, id: any = null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingStakeholder());
    if (id !== null && id !== undefined) {
      await APIService.put(`/stakeholders/${id}`, data).then((response) => {
        dispatch(resetStakeholder(nextAction));
      });
    } else {
      await APIService.post("/stakeholders", data).then((response) => {
        dispatch(resetStakeholder(nextAction));
      });
    }
  } catch (e) {
    // dispatch(setCustomerError());
  }
};

export const stakeholderDelete = (id: string) => async (dispatch: AppDispatch) => {
  try {
    await APIService.delete(`/stakeholders/${id}`).then((response) => {
    });
  } catch (e) {
  }
};

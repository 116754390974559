import { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InfoPopProps {
  infoElementName: string;
  shortText: string;
  position?: any;
  longText?: any;
}

export const InfoPop: FC<InfoPopProps> = (props) => {
  const { infoElementName, shortText, position, longText } = props;
  console.log(props)
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  return (
    <>
      <Tooltip title={shortText} placement={position} arrow>
        <IconButton
          color="inherit"
          // disabled={longText === null || longText === undefined}
          onClick={() => (longText === null || longText === undefined) ? setOpenInfoDialog(false) : setOpenInfoDialog(true)}
          sx={{
            marginRight: 0.5,
            display: {
              md: 'inline-flex',
              xs: 'none'
            },
            borderRadius: '100px',
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle as IconProp} size="xs" color='grey' />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      >
        <DialogTitle>
          {infoElementName}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            my={2}
          >
            <Grid
              item
              xs={12}
            >
              <div dangerouslySetInnerHTML={{ __html: longText }} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

InfoPop.propTypes = {
  infoElementName: PropTypes.string,
  shortText: PropTypes.string,
  position: PropTypes.string,
  longText: PropTypes.string
};

import { FC, useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Divider} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { InputField } from '../input-field';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { bugReportPost, resetBugReportStatus } from '../../slices/bugReportSlice';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';
import { getActiveElement } from '../../utils/active-element';
import { useTranslation } from 'react-i18next';

interface  BugReportDialogProps {
  open: boolean;
  onClose: () => void;
}

export const  BugReportDialog: FC< BugReportDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const bugReport = useSelector((state: RootState) => state.bugReport);
  const dispatch = useDispatch();

  const location = useLocation();
  const activeElement = getActiveElement(location.pathname);

  let initialValues = {
    message: '',
    submit: null
  };

  useEffect(() => {
    if (bugReport.bugReportStatus === 'success') {
      toast.success('BugReport created');
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      dispatch(resetBugReportStatus());
      if (bugReport.bugReportNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: bugReport.bugReportStatus });
      formik.setSubmitting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bugReport.bugReportStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      message: Yup.string().min(3).max(255).required('Message is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleSubmitAndQuit = () => {
    dispatch(bugReportPost('close', {...formik.values, ...activeElement}));
  };

  return (
    <Dialog
      // maxWidth="md"
      fullWidth
      onClose={() => {setOpenConfirmationDialog(true)}}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle sx={{ backgroundColor: "background.default" }}>
        {t('create_bug_report')}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          my={2}
        >
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.message && formik.errors.message)}
              fullWidth
              helperText={formik.touched.message && formik.errors.message}
              label={t('message')}
              name="message"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.message}
              multiline
              rows={4}
            />
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ backgroundColor: "background.default" }}>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          size="small"
          // disabled={bugReportIsLoading}
          // disabled={!(formik.isValid && formik.dirty) || bugReport.bugReportIsLoading}
          disabled={(bugReport.bugReportIsLoading || !bugReport.isEditabled)}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
          fullWidth
        >
          {t('create')}
        </Button>
      </DialogActions>
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

 BugReportDialog.defaultProps = {
  open: false
};

 BugReportDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};

import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { postDocumentToS3 } from "../services/s3.service";
import { AppDispatch } from "../store";

const initialState = {
  documentDialogOpen: false,
  documentIsLoading: false,
  documentStatus: null,
  documentNextAction: null
};

export const document = createSlice({
  name: "document",
  initialState,
  reducers: {
    openDocumentDialog: (state, action) => {
      state.documentDialogOpen = true;
    },
    closeDocumentDialog: (state) => {
      state.documentDialogOpen = false;
    },
    loadingDocument: (state) => {
      state.documentIsLoading = true;
    },
    resetDocument: (state, action) => {
      state.documentIsLoading = false;
      state.documentStatus = 'success';
      state.documentNextAction = action.payload;
    },
    resetDocumentStatus: (state) => {
      state.documentStatus = null;
    }
  },
});

export const {
  openDocumentDialog,
  closeDocumentDialog,
  loadingDocument,
  resetDocument,
  resetDocumentStatus
} = document.actions;

export default document.reducer;

export const documentPost = (nextAction, data: any, file) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingDocument());
    await APIService.post("/documents", data).then(async (response) => {
      await postDocumentToS3(response.data.data.result, file).then((s3Response) => {
        dispatch(resetDocument(nextAction));
      });
    });
  } catch (e) {
    // dispatch(setCustomerError());
  }
};

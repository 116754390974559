import { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Divider,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@material-ui/core';
import { InputField } from '../input-field';
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog }  from '../confirmation-dialog';
import { movePost, resetMoveStatus, updateField } from '../../slices/moveSlice';
import { review4GetAll } from '../../slices/review4Slice';
import { review3GetAll } from '../../slices/review3Slice';
import { unknownGetAll } from '../../slices/unknownSlice';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';
import { getActiveElement } from '../../utils/active-element';
import { Wizard } from './move-wizard';
import { useTranslation } from 'react-i18next';
import { InfoPop } from '../info-pop';
import { openMoveDialog } from 'src/slices/moveSlice';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';

interface MoveDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MoveDialog: FC<MoveDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const move = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();
  const { moveIsLoading, moveStatus, moveNextAction } = useSelector((state: RootState) => state.move);

  const {moves} = useSelector((state: RootState) => state.review4);

  const [showList, setShowList] = useState(true);

  const [height, setHeight] = useState(0)
  const mainGridRef = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  useEffect(() => {
    if (!refVisible) {
      return
    }
    setHeight(mainGridRef.current.clientHeight)
  }, [refVisible, showDetails, mainGridRef?.current?.clientHeight])


  const location = useLocation();
  const activeElement = getActiveElement(location.pathname);

  useEffect(() => {
    console.log('store status', moveIsLoading);
    if (moveStatus === 'success') {
      toast.success('Move created');
      dispatch(review4GetAll());
      dispatch(review3GetAll());
      dispatch(unknownGetAll());
      dispatch(resetMoveStatus());
      if (moveNextAction === 'close') {
        onClose();
      }
    }
  }, [moveStatus]);

  const handleSubmit = () => {
    console.log('Submit and quit');
    let values = {
      name: move.name,
      comment: move.comment,
      selectedForActionPlan: move.selectedForActionPlan,
      launchPlan: move.launchPlan,
      reduceUnknownPlan: move.reduceUnknownPlan,
      decisionForActionPlan: move.decisionForActionPlan,
      concerning: move.concerning,
      collateralEffect: move.collateralEffect,
      startDate: move.startDate,
      finishDate: move.finishDate,
      estimatedCost: move.estimatedCost,
      userIdeaId: move.userIdeaId,
      descriptionOfTheMove: move.descriptionOfTheMove,
      kpi: move.kpi,
      goalDate: move.goalDate,
      descriptionOfTheGoal: move.descriptionOfTheGoal,
      costing: move.costing,
      detailedImpact: move.detailedImpact
    };
    console.log('new stakeholder', values);
    dispatch(movePost('close', values, move.moveId));
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={() => {setOpenConfirmationDialog(true)}}
      open={open}
      // TransitionProps={{
      //   onExited: () => formik.resetForm()
      // }}
      PaperProps={{
        style: { borderRadius: 25 }
      }}
      {...other}
    >
      {/* <DialogTitle>
        {(move.moveId !== null && move.moveId !== undefined) ? `${t('update_move')} : ${move.name}` : t('create_move')}
        <div style={{ display: 'inline-block'}}>
          <InfoPop
            infoElementName={t('moves')}
            shortText='What is a Tactical Move?'
            position='right'
            longText='A Tactical Move is an action taken to reduce Unknowns or to implement the Solution/product. A Move can be selected to contribute to the Action Plan, to the Implementation Plan or to the Plan to reduce Unknowns. For each Move you should clarify its objective/purpose, its timeline, its cost, the resource requirements, its impact on Stakeholders, and potential collateral effects'
          />
        </div>
      </DialogTitle> */}
      <DialogContent sx={{ padding: 0, backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          // my={2}
        >
          <Grid item xs={showList ? 8 : 12} sx={{ p: 0, backgroundColor: "background.default" }}>
            <Grid container spacing={2} padding={4} sx={{ overflowY: "scroll" }} maxHeight={600} ref={el => {mainGridRef.current = el; setRefVisible(!!el);}}>
              <Grid item xs={12}>
                <Typography variant="h5" color={'success.main'}>
                {(move.moveId !== null && move.moveId !== undefined) ? `${t('update_move')} : ${move.name}` : t('create_move')}
                  {/* <div style={{ display: 'inline-block'}}>
                    <InfoPop
                      infoElementName={t('unknowns')}
                      shortText='What is a Unknown?'
                      position='right'
                      longText='Unknowns exist when information or knowledge are missing. Once the missing information has been obtained, the Unknown no longer exists. Since Unknowns are only testifying of your ignorance, they have no probability of occurrence like it is the case for Risks.<br />
                      Unknowns can relate to Customers, technology, market, regulations, Stakeholders, organizational issues,Resources, etc.<br />
                      Once Unknowns have been identified, you can figure out the effort (time, money, people, etc.) needed to obtain the missing information/knowledge, which will eventually reduce the Unknowns. You should make sure to reduce Unknowns in the optimal sequence that consumes the least amount of resources (money, time, etc.). <br />
                      To optimize the sequence you should establis an as complete as possible inventory of all identifiable Unknowns. <br />
                      Any Unknown that has not been identified reduces your ability to realistically assess the effort required to reduce Unknowns and/or deliver the Definition of Success.'
                    />
                  </div> */}
                  <div style={{ display: 'inline-block', textAlign: 'right', float: 'right' }}>
                  <IconButton onClick={() => setShowList(!showList)}>
                    {showList ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t('name')}
                  name="name"
                  // onBlur={formik.handleBlur}
                  onChange={(e) => dispatch(updateField({field: 'name', value: e.target.value}))}
                  required
                  type="name"
                  value={move.name}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t('comment')}
                  name="comment"
                  // onBlur={formik.handleBlur}
                  onChange={(e) => dispatch(updateField({field: 'comment', value: e.target.value}))}
                  type="comment"
                  value={move.comment}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  type="button"
                  color='success'
                >
                  {showDetails === true ? t('hide_details') : t('show_details')}
                </Button>
              </Grid>
              {showDetails === true ? (
                <Grid item xs={12}>
                  <Wizard data={move} />
                </Grid>
              ) : (<></>)}
              <Grid><Divider /></Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="success"
                  size="small"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mx: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  size="small"
                  disabled={(move.moveIsLoading || !move.isEditabled || (move.name.length < 3))}
                  onClick={() => { handleSubmit(); }}
                  variant="contained"
                  sx={{ mx: 1 }}
                >
                  {(move.moveId !== null && move.moveId !== undefined) ? t('update') : t('create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          { showList && (
              <Grid item xs={4} sx={{ backgroundColor: "success.main", overflowY: "scroll" }} padding={0} spacing={2} height={height}>
                <Grid container spacing={2} padding={4} sx={{paddingLeft: 2}}>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Typography variant="h5" style={{ color: "white" }}>
                      All moves
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {moves?.map((u) => (
                        <ListItem disablePadding sx={{ backgroundColor: "white", marginBottom: 1, borderRadius: 1, padding: 0, fontSize: "10px" }}>
                          <ListItemButton sx={{ padding: 0, fontSize: "10px" }}>
                            <ListItemText
                              onClick={() => dispatch(openMoveDialog({element: u, isEditabled: true, isDuplicated: false}))}
                              primary={u.name}
                              sx={{ padding: "5px", margin: 0, borderRadius: 1, fontSize: "10px" }} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <Divider />
      {/* <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(move.moveIsLoading || !move.isEditabled || (move.name.length < 3))}
          onClick={() => { handleSubmit(); }}
          variant="contained"
        >
          {(move.moveId !== null && move.moveId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions> */}
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

MoveDialog.defaultProps = {
  open: false
};

MoveDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};

// export const lightPrimary = {
//   contrast: '#ffffff',
//   dark: '#ba000d',
//   light: '#ff7961',
//   main: '#f44336'
// };

// export const lightSecondary = {
//   contrast: '#ffffff',
//   dark: '#ba000d',
//   light: '#ff7961',
//   main: '#f44336'
// };

export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#274472',
  light: '#C3E0E5',
  main: '#5e6af6'
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#ba000d',
  light: '#ff7961',
  main: '#f44336'
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#a53531',
  light: 'rgba(236, 76, 71, 0.5)',
  main: '#ec4c47'
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#c43e00',
  light: '#ffa040',
  main: '#FF7A00'
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0b4e8d',
  light: '#3f8cd4',
  main: '#1070CA'
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#1b774d',
  light: 'rgba(39, 170, 109, 0.5)',
  main: '#27AB6E'
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#f5f5f5',
  row: '#fafafa'
};

export const lightText = {
  primary: '#333333',
  secondary: '#263238'
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#999',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#333'
};

export const darkPrimary = {
  contrast: '#ffffff',
  dark: '#ba000d',
  light: '#ff7961',
  main: '#5e6af6'
};

export const darkSecondary = {
  contrast: '#ffffff',
  dark: '#ba000d',
  light: '#ff7961',
  main: '#f44336'
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#eb6d57',
  main: '#e6492d'
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#c43e00',
  light: '#ffa040',
  main: '#FF7A00'
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#1D4580',
  light: '#4483df',
  main: '#1664d8'
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#2F6b39',
  light: '#5fc16d',
  main: '#38b249'
};

export const darkBackground = {
  default: '#1e1f24',
  paper: '#131517',
  row: '#141517'
};

export const darkText = {
  primary: '#ffffff',
  secondary: '#f5f5f5'
};

export const darkNeutral = {
  100: '#1e212a',
  200: '#2b2f3c',
  300: '#40444f',
  400: '#545863',
  500: '#6c6f7f',
  600: '#868998',
  700: '#a1a4af',
  800: '#bdbfc7',
  900: '#d8dade'
};

import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  logSidebarPinned: false,
  elementName: '',
  elementField: '',
  logs: [],
  isLoading: true
};

export const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    setLogs: (state, action) => {
      state.logs = action.payload.logs;
      state.elementName = action.payload.elementName;
      state.elementField = action.payload.elementField;
      state.logSidebarPinned = true;
      state.isLoading = false;
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    unpinSidebar: (state) => {
      // state = initialState;
      state.logSidebarPinned = false;
    }
  },
});

export const {
  setLogs,
  setIsLoading,
  unpinSidebar
} = logSlice.actions;

export default logSlice.reducer;

export const getLogs = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading());
    await APIService.get("/logs", {params: params}).then((response) => {
      dispatch(setLogs({elementName: params.element_name, elementField: params.element_field, logs: response.data.data.result}));
    });
  } catch (e) {
    // dispatch(setCustomerError());
  }
};

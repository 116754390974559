import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  unknownDialogOpen: false,
  unknownId: null,
  description: null,
  comment: null,
  outcome: null,
  noGo: false,
  move: null,
  positiveImpact: 1,
  negativeImpact: 1,
  concernings: [],
  isEditabled: true,
  isDuplicated: false,
  unknownIsLoading: false,
  unknownStatus: null,
  unknownNextAction: null,
  unknowns: [],
};

export const unknownSlice = createSlice({
  name: "unknown",
  initialState,
  reducers: {
    openUnknownDialog: (state, action) => {
      state.unknownDialogOpen = true;
      if (action.payload !== {}) {
        // Element
        if (action.payload.isDuplicated === true) {
          state.unknownId = null;
          state.description = `${action.payload.element.description} - copy`;
        } else {
          state.unknownId = action.payload.element.id;
          state.description = action.payload.element.description;
        }
        state.comment = action.payload.element.comment;
        state.outcome = action.payload.element.outcome;
        state.noGo = action.payload.element.no_go;
        state.positiveImpact = action.payload.element.positive_impact;
        state.negativeImpact = action.payload.element.negative_impact;
        state.concernings = action.payload.element.concernings;
        state.move = action.payload.element.move;
        // Other
        state.isEditabled = action.payload.isEditabled;
        state.isDuplicated = action.payload.isDuplicated;
      }
    },
    closeUnknownDialog: (state) => {
      state.unknownDialogOpen = false;
    },
    loadingUnknown: (state) => {
      state.unknownIsLoading = true;
    },
    resetUnknown: (state, action) => {
      state.description = '';
      state.comment = '';
      state.outcome = '';
      state.noGo = false;
      state.positiveImpact = 1;
      state.negativeImpact = 1;
      state.concernings = [];
      state.unknownIsLoading = false;
      state.unknownStatus = 'success';
      state.unknownNextAction = action.payload;
    },
    resetUnknownStatus: (state) => {
      state.unknownStatus = null;
    },
    putUnknowns: (state, action) => {
      console.log('unknown slice', action.payload)
      Object.assign(state.unknowns, action.payload)
    }
  },
});

export const {
  openUnknownDialog,
  closeUnknownDialog,
  loadingUnknown,
  resetUnknown,
  resetUnknownStatus,
  putUnknowns
} = unknownSlice.actions;

export default unknownSlice.reducer;

export const unknownPost = (nextAction, data: any, id: any = null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingUnknown());
    if (id !== null && id !== undefined) {
      await APIService.put(`/unknowns/${id}`, data).then((response) => {
        dispatch(resetUnknown(nextAction));
      });
    } else {
      await APIService.post("/unknowns", data).then((response) => {
        dispatch(resetUnknown(nextAction));
      });
    }
  } catch (e) {
    console.log(e);
    // dispatch(setCustomerError());
  }
};

export const unknownGetAll = () => async (dispatch: AppDispatch) => {
  try {
    await APIService.get("/unknowns").then((response) => {
      dispatch(putUnknowns(response.data.data.result));
    });

  } catch (e) {
    console.log(e);
    // dispatch(setCustomerError());
  }
};

export const unknownDelete = (id: string) => async (dispatch: AppDispatch) => {
  try {
    await APIService.delete(`/unknowns/${id}`).then((response) => {
      console.log(response);
    });
  } catch (e) {
    console.log(e);
  }
};
export const access = {
    1: {
        label: 'editor',
        color: 'success'
    },
    2: {
        label: 'editor',
        color: 'warning'
    },
    3: {
        label: 'editor',
        color: 'error'
    }
}

export const confidenceIndice = {
    1: {
        color: '#b7bdcc',
        label: 'unaddressed'
    },
    2: {
        color: '#f44336',
        label: 'quick_and_dirty'
    },
    3: {
        color: '#d9822b',
        label: 'medium'
    },
    4: {
        color: '#52bb8b',
        label: 'advanced'
    },
    5: {
        color: '#1b774d',
        label: 'reliable'
    }
}

export const concernings = {
    1: {label: 'team'},
    2: {label: 'collateral_effects'},
    3: {label: 'resources'},
    4: {label: 'action_plan'},
    5: {label: 'stakeholders'},
    6: {label: 'factors'},
    7: {label: 'definition_of_success_and_failure'},
    8: {label: 'economic_model'},
    9: {label: 'activity_chain'},
    10: {label: 'scope'},
    11: {label: 'company_decision_criteria'},
    12: {label: 'solution'},
    13: {label: 'customer_benchmarking'},
    14: {label: 'customer_knowledge'},
    15: {label: 'customer_pnds'},
    16: {label: 'Build Barrier to entry'},
}

export const decisions = [
    {
      value: 1,
      label: 'must_be_done'
    },
    {
      value: 2,
      label: 'should_be_done'
    },
    {
      value: 3,
      label: 'nice_to_do'
    },
    {
      value: 4,
      label: 'not_worth_it'
    },
]

export const decisionsCell = {
    1: {label: 'must_be_done'},
    2: {label: 'should_be_done'},
    3: {label: 'nice_to_do'},
    4: {label: 'not_worth_it'}
}

export const moveConcernings = {
    1: {label: 'manage_stakeholders'},
    2: {label: 'fit_company'},
    3: {label: 'reduce_resitances'},
    4: {label: 'reduce_unknowns'},
    5: {label: 'reduce_constraints'},
    6: {label: 'reduce_risks'},
    7: {label: 'overcome_obstacles'},
    8: {label: 'benefit_of_boosters'},
    9: {label: 'create_barrier_to_entry'},
    10: {label: 'deliver_benefits'},
    11: {label: 'convince_customers'},
    12: {label: 'increase_market_penetration'},
    13: {label: 'obtain_the_resource'},
    14: {label: 'reduce_collateral_effect'},
    15: {label: 'support_the_team'},
}

export const moveDetailedImpacts = {
    1: {label: 'definition_of_success'},
    2: {label: 'client_decision_criteria'},
    3: {label: 'unknowns'},
    4: {label: 'obstacles'},
    5: {label: 'risks'},
    6: {label: 'boosters'},
    7: {label: 'constraints'},
    8: {label: 'resistances'},
    9: {label: 'aspirations'}
}
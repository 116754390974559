import { Button, ButtonGroup, useTheme } from "@material-ui/core";
import { FC } from "react";

type pndValues = 0 | 1 | 2 | 3;

interface PndSelectorProps {
  currentPnd?: pndValues;
  disabled?: boolean;
  setPnd?: (pnd: pndValues) => void;
}


export const PndSelector: FC<PndSelectorProps> = (props) => {
  const theme = useTheme()
  const colors = {
    1: theme.palette.primary.main,
    2: theme.palette.warning.main,
    3: theme.palette.success.main,
  }
  const getVariant = (pnd: pndValues,) => 
    props.currentPnd === pnd ? 'contained' : 'outlined';
  const getSx = (pnd: pndValues) => {
    if (props.currentPnd !== pnd) {
      return {}
    }

    return {
      backgroundColor: colors[pnd],
      color: 'white',
    }
  }

  return (
    <ButtonGroup disabled={props.disabled}>
      <Button 
        // onClick={() => props.setPnd(1)}
        variant={getVariant(1)}
        color="primary"
        style={getSx(1)}
      >
        P
      </Button>

      <Button 
        // onClick={() => props.setPnd(1)}
        variant={getVariant(2)}
        color="primary"
        style={getSx(2)}
      >
        N
      </Button>

      <Button 
        // onClick={() => props.setPnd(1)}
        variant={getVariant(3)}
        color="primary"
        style={getSx(3)}
      >
        D
      </Button>
    </ButtonGroup>
  )
}

PndSelector.defaultProps = {
  disabled: false,
  currentPnd: 0,
  setPnd: () => {}
}
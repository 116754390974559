import axios from '../services/api.service';

export const projectGetAll = async (data: object) => {
  try {
    const { data: response } = await axios.get('/projects', data);
    return response.data.result;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetOne = async () => {
  try {
    const { data: response } = await axios.get('/projects/active');
    return response.data.result;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectPutOne = async (data: object) => {
  try {
    await axios.put('/projects/active', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectCreate = async (data: object) => {
  try {
    await axios.post('/projects', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectUpdateActive = async (data: object) => {
  try {
    await axios.post('/projects/update-active-project', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetMembers = async () => {
  try {
    const { data: response } = await axios.get('/projects/members');
    return response.data.result;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetStakeholders = async () => {
  try {
    const { data: response } = await axios.get('/projects/stakeholders');
    return response.data.result;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup,FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../../input-field';
import { DateField } from '../../date-field';
import { decisions, moveConcernings } from '../../../static-data';
import { projectGetMembers } from '../../../api/project';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from "react-redux";
import { stakeholderPost, resetStakeholderStatus, updateField } from '../../../slices/stakeholderSlice';
import { InfoPop } from '../../info-pop';


export const WizardProfile: FC = () => {
  const { t } = useTranslation();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const stakeholder = useSelector((state: RootState) => state.stakeholder);
  const dispatch = useDispatch();

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
              <div>
                <Grid
                  container
                  spacing={2}
                >
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      label={t('name')}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                    >
                      {t('level_of_power')}
                      <div style={{ display: 'inline-block'}}>
                        <InfoPop
                          infoElementName={t('level_of_power')}
                          shortText='Level of power that has been verified. If you are not sure, use question mark'
                          position='right'
                        />
                      </div>
                    </Typography>
                    <ToggleButtonGroup
                      value={stakeholder.stakeholderProfile.levelOfPower}
                      onChange={(e, newAlignment) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'levelOfPower', value: newAlignment}))}
                      id="positiveImpact"
                      exclusive
                      fullWidth
                      size='small'
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: 'background.paper', color: 'text.primary'}, padding: 0.25, fontSize: "10px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FFF2E6', color: '#000'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FFE4CC', color: '#000'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        1
                      </ToggleButton>
                      <ToggleButton value={2} id="positiveImpact" aria-label="centered" sx={{ '&.Mui-selected': {backgroundColor: '#FFCA99', color: '#000'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        2
                      </ToggleButton>
                      <ToggleButton value={3} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FFAF66', color: '#000'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignRightIcon /> */}
                        3
                      </ToggleButton>
                      <ToggleButton value={4} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FF9533', color: '#FFF'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignRightIcon /> */}
                        4
                      </ToggleButton>
                      <ToggleButton value={5} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FF7A00', color: '#FFF'}, padding: 0.25, fontSize: "10px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        5
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                    >
                      {t('level_of_support')}
                      <div style={{ display: 'inline-block'}}>
                        <InfoPop
                          infoElementName={t('level_of_support')}
                          shortText='Level of support that has been verified. If it has not been verified, use question mark'
                          position='right'
                        />
                      </div>
                    </Typography>
                    <ToggleButtonGroup
                      value={stakeholder.stakeholderProfile.levelOfSupport}
                      onChange={(e, newAlignment) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'levelOfSupport', value: newAlignment}))}
                      id="positiveImpact"
                      exclusive
                      fullWidth
                      size='small'
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: 'background.paper', color: 'text.primary'}, padding: 0.25, fontSize: "10px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FF381A', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FF644D', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        1
                      </ToggleButton>
                      <ToggleButton value={2} id="positiveImpact" aria-label="centered" sx={{ '&.Mui-selected': {backgroundColor: '#FF9180', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        2
                      </ToggleButton>
                      <ToggleButton value={3} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#77D968', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignRightIcon /> */}
                        3
                      </ToggleButton>
                      <ToggleButton value={4} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#49CC36', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignRightIcon /> */}
                        4
                      </ToggleButton>
                      <ToggleButton value={5} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#1CBF04', color: 'success.contrastText'}, padding: 0.25, fontSize: "10px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        5
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      value={stakeholder.stakeholderProfile.howImpacted}
                      onChange={(e) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'howImpacted', value: e.target.value}))}
                      // error={Boolean(formik.touched.howImpacted && formik.errors.howImpacted)}
                      fullWidth
                      // helperText={formik.touched.howImpacted && formik.errors.howImpacted}
                      label={t('how_impacted')}
                      name="howImpacted"
                      // onBlur={formik.handleBlur}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      value={stakeholder.stakeholderProfile.benefits}
                      onChange={(e) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'benefits', value: e.target.value}))}
                      // error={Boolean(formik.touched.howImpacted && formik.errors.howImpacted)}
                      fullWidth
                      // helperText={formik.touched.howImpacted && formik.errors.howImpacted}
                      label={t('stakeholder_benefits')}
                      name="benefits"
                      // onBlur={formik.handleBlur}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      value={stakeholder.stakeholderProfile.collateralDamages}
                      onChange={(e) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'collateralDamages', value: e.target.value}))}
                      // error={Boolean(formik.touched.howImpacted && formik.errors.howImpacted)}
                      fullWidth
                      // helperText={formik.touched.howImpacted && formik.errors.howImpacted}
                      label={t('stakeholder_collateral_damages')}
                      name="collateralDamages"
                      // onBlur={formik.handleBlur}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
                </Grid>
              </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup,FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { DateField } from '../date-field';
import { decisions, moveConcernings } from '../../static-data';
import { projectGetMembers } from '../../api/project';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSlice';
import { InfoPop } from '../info-pop';

export const WizardGeneral: FC = () => {
  const { t } = useTranslation();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [users, setUsers] = useState([]);

  const move = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();

  const getUsers = async () => {
    const _data = await projectGetMembers();
    setUsers(_data);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSelectedPlan = (event: React.ChangeEvent<HTMLInputElement>, plan: string) => {
    // formik.setFieldValue(plan, event.target.checked);
    // dispatch(updateField())
  };

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
              <div>
                <Grid
                  container
                  spacing={2}
                >
                  {/* <Grid
                    item
                    xs={12}
                    md={8}
                  >
                    <InputField
                      // error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      // helperText={formik.touched.name && formik.errors.name}
                      label={t('name')}
                      name="name"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'name', value: e.target.value}))}
                      value={move.name}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Tooltip title={"The selection of coordinated Tactical Moves for delivering an outcome."} placement={'bottom'} arrow>
                      <FormGroup style={{ marginTop: '20px'}}>
                        <FormControlLabel control={<Switch
                          checked={move.selectedForActionPlan}
                          onChange={(e) => dispatch(updateField({field: 'selectedForActionPlan', value: e.target.checked}))}
                        />} label={t('selected_for_action_plan')} />
                      </FormGroup>
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Tooltip title={"The selection of Tactical Moves that are expected to deliver the Definition of Success "} placement={'bottom'} arrow>
                      <FormGroup style={{ marginTop: '20px'}}>
                        <FormControlLabel control={<Switch
                          checked={move.launchPlan}
                          onChange={(e) => dispatch(updateField({field: 'launchPlan', value: e.target.checked}))}
                        />} label={t('selected_for_launch_plan')} />
                      </FormGroup>
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4}
                  >
                    <Tooltip title={"The selection of Tactical Moves for reducing Unknowns "} placement={'bottom'} arrow>
                      <FormGroup style={{ marginTop: '20px'}}>
                        <FormControlLabel control={<Switch
                          checked={move.reduceUnknownPlan}
                          onChange={(e) => dispatch(updateField({field: 'reduceUnknownPlan', value: e.target.checked}))}
                        />} label={t('selected_for_reduce_unknown_plan')} />
                      </FormGroup>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      // error={Boolean(formik.touched.decisionForActionPlan && formik.errors.decisionForActionPlan)}
                      fullWidth
                      // helperText={formik.touched.decisionForActionPlan && formik.errors.decisionForActionPlan}
                      label={t('decision_for_action_plan')}
                      name="decisionForActionPlan"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'decisionForActionPlan', value: e.target.value}))}
                      select
                      value={move.decisionForActionPlan}
                    >
                      {decisions.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {t(option.label)}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      // error={Boolean(formik.touched.concerning && formik.errors.concerning)}
                      fullWidth
                      // helperText={formik.touched.concerning && formik.errors.concerning}
                      label={t('concerning')}
                      name="concerning"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'concerning', value: e.target.value}))}
                      select
                      value={move.concerning}
                    >
                      {Object.keys(moveConcernings).map((mc) => (
                        <MenuItem
                          key={mc}
                          value={mc}
                        >
                          {t(moveConcernings[mc]['label'])}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <span style={{
                      fontSize: 12,
                      fontWeight: 500,
                      position: 'relative',
                      transform: 'none',
                      // textDecoration: 'line-through'
                    }}>{t('collateral_effects')}</span>
                    <div style={{ display: 'inline-block'}}>
                      <InfoPop
                        infoElementName={t('collateral_effects')}
                        shortText='What is a Collatoral effect?'
                        position='right'
                        longText={'Some of the tactical Moves in your Action Plan may produce collateral effects that could  directly or indirectly upset some of your Stakeholders. You need to identify them in order to anticipate how to manage those negative consequences of your Action Plan (by modifying the Plan to avoid certain Collateral Effects or by preparing a Plan B that mitigates the collateral dammages)'}
                      />
                    </div>
                    <InputField
                      // error={Boolean(formik.touched.collateralEffect && formik.errors.collateralEffect)}
                      fullWidth
                      // helperText={formik.touched.collateralEffect && formik.errors.collateralEffect}
                      // label={t('collateral_effects')}
                      name="collateralEffect"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'collateralEffect', value: e.target.value}))}
                      value={move.collateralEffect}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <DateField
                      // error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                      fullWidth
                      // helperText={formik.touched.startDate && formik.errors.startDate}
                      label={t('start_date')}
                      name="startDate"
                      onChange={(date) => dispatch(updateField({field: 'startDate', value: date}))}
                      value={move.startDate}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <DateField
                      // error={Boolean(formik.touched.finishDate && formik.errors.finishDate)}
                      fullWidth
                      // helperText={formik.touched.finishDate && formik.errors.finishDate}
                      label={t('end_date')}
                      name="finishDate"
                      onChange={(date) => dispatch(updateField({field: 'finishDate', value: date}))}
                      value={move.finishDate}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      // error={Boolean(formik.touched.estimatedCost && formik.errors.estimatedCost)}
                      fullWidth
                      // helperText={formik.touched.estimatedCost && formik.errors.estimatedCost}
                      label={t('estimated_cost')}
                      name="estimatedCost"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'estimatedCost', value: e.target.value}))}
                      value={move.estimatedCost}
                      placeholder="100"
                      type="number"
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">€&nbsp;&nbsp;</InputAdornment>),
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      // error={Boolean(formik.touched.userIdeaId && formik.errors.userIdeaId)}
                      fullWidth
                      // helperText={formik.touched.userIdeaId && formik.errors.userIdeaId}
                      label={t('user')}
                      name="userIdeaId"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'userIdeaId', value: e.target.value}))}
                      select
                      value={move.userIdeaId}
                    >
                      {users?.map((u) => (
                        <MenuItem
                          key={u.id}
                          value={u.id}
                        >
                          {u.user_name}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
                </Grid>
              </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  commentDialogOpen: false,
  commentIsLoading: false,
  commentStatus: null,
  commentNextAction: null,
  commentId: null,
  message: '',
  isShared: false,
  isEditabled: true,
  isDuplicated: false,
};

export const comment = createSlice({
  name: "comment",
  initialState,
  reducers: {
    openCommentDialog: (state, action) => {
      state.commentDialogOpen = true;
      if (action.payload.isDuplicated === true) {
        state.commentId = null;
        state.message = `${action.payload.element.message} - copy`;
      } else {
        state.commentId = action.payload.element.id;
        state.message = action.payload.element.message;
      }
      state.isShared = action.payload.element.isShared;
      // Other
      state.isEditabled = action.payload.isEditabled;
      state.isDuplicated = action.payload.isDuplicated;
    },
    closeCommentDialog: (state) => {
      state.commentDialogOpen = false;
    },
    loadingComment: (state) => {
      state.commentIsLoading = true;
    },
    resetComment: (state, action) => {
      state.commentIsLoading = false;
      state.commentStatus = 'success';
      state.commentNextAction = action.payload;
    },
    resetCommentStatus: (state) => {
      state.commentStatus = null;
    }
  },
});

export const {
  openCommentDialog,
  closeCommentDialog,
  loadingComment,
  resetComment,
  resetCommentStatus
} = comment.actions;

export default comment.reducer;

export const commentPost = (nextAction, data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingComment());
    await APIService.post("/comments", data).then((response) => {
      dispatch(resetComment(nextAction));
    });
  } catch (e) {
    // dispatch(setCustomerError());
  }
};


export const commentDelete = (id: string) => async (dispatch: AppDispatch) => {
  try {
    await APIService.delete(`/comments/${id}`).then((response) => {
    });
  } catch (e) {
  }
};

import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  customers: {
    data: [],
    isLoading: true,
    error: null,
    status: null,
  },
  companies: {
    data: [],
    isLoading: true,
    error: null,
    status: null,
  },
  companyFit: null,
  history: {
    object: null,
    field: null,
    data: [],
  },
  activeCustomer: null,
  activeCompany: null,
  review1CustomerIsInitialized: false,
  review1CompanyIsInitialized: false
};

export const review1Slice = createSlice({
  name: "review1",
  initialState,
  reducers: {
    initCustomerStatus: (state) => {
      state.customers.status = null;
    },
    initCompanyStatus: (state) => {
      state.companies.status = null;
    },
    postCustomer: (state, action) => {
      state.customers.data = [...state.customers.data, action.payload];
      state.customers.isLoading = false;
      state.customers.status = "success";
    },
    putCustomer: (state, action) => {
      const newData = [...state.customers.data];
      const index = newData.findIndex((x) => x.id === action.payload.id);
      newData[index] = action.payload;
      state.customers.data = newData;
      state.customers.isLoading = false;
      state.customers.status = "success";
      state.activeCustomer = newData[index];
    },
    deleteCustomer: (state, action) => {
      const newData = [...state.customers.data];
      const index = newData.findIndex((x) => x.id === action.payload.id);
      newData.splice(index, 1);
      state.customers.data = newData;
      state.customers.isLoading = false;
      state.customers.status = "success";
    },
    getCustomers: (state, action) => {
      console.log('GET CUS', action.payload);
      state.customers.data = action.payload;
      state.customers.isLoading = false;
      state.review1CustomerIsInitialized = true;
      state.customers.data.forEach((customer) => {
        customer.confidence_indice = Math.min(
          customer.pnd.confidence_indice,
          customer.benchmarking.confidence_indice,
          customer.experience.confidence_indice
        )
      });
    },
    setCustomersLoading: (state) => {
      state.customers.isLoading = true;
    },
    setCustomerError: (state) => {
      state.customers.status = "error";
    },
    setActiveCustomer: (state, action) => {
      state.activeCustomer = state.customers.data.find(
        (x) => x.id === action.payload.id
      );
    },
    putCustomerDetails: (state, action) => {
        const cus = action.payload.newData ? action.payload.newData : action.payload.data;
        const newData = [...state.customers.data];
        const index = newData.findIndex((x) => x.id === action.payload.id);
        Object.keys(cus).forEach((k) => {
          newData[index][action.payload.detailType][k] = cus[k];
        });
        state.customers.data = newData;
        state.customers.isLoading = false;
        state.customers.status = "success";
        state.activeCustomer = newData[index];
        state.activeCustomer.confidence_indice = Math.min(
          state.activeCustomer.pnd.confidence_indice,
          state.activeCustomer.benchmarking.confidence_indice,
          state.activeCustomer.experience.confidence_indice
        );
    },
    // COMPANY PART
    getCompany: (state, action) => {
      state.companyFit = action.payload;
      state.review1CompanyIsInitialized = true;
    },
    postCompany: (state, action) => {
      state.companies.data = [...state.companies.data, action.payload];
      state.companies.isLoading = false;
      state.companies.status = "success";
    },
    getCompanies: (state, action) => {
      state.companies.data = action.payload;
      state.companies.isLoading = false;
      state.review1CompanyIsInitialized = true;
      state.companies.data.forEach((company) => {
        company.confidence_indice = Math.min(
          company.benefits.confidence_indice,
          company.benchmarking.confidence_indice,
          company.alignement.confidence_indice
        )
      });
    },
    putCompany: (state, action) => {
      const newData = [...state.companies.data];
      const index = newData.findIndex((x) => x.id === action.payload.id);
      newData[index] = action.payload;
      state.companies.data = newData;
      state.companies.isLoading = false;
      state.companies.status = "success";
      state.activeCompany = newData[index];
    },
    setCompaniesLoading: (state) => {
      state.companies.isLoading = true;
    },
    setActiveCompany: (state, action) => {
      state.activeCompany = state.companies.data.find(
        (x) => x.id === action.payload.id
      );
    },
    // putCompanyDetails: (state, action) => {
    //   const cus = action.payload.data;
    //   const newData = {...state.companyFit};
    //   console.log(cus, newData);
    //   Object.keys(cus).forEach((k) => {
    //     newData[action.payload.detailType][k] = cus[k];
    //   });
    //   state.companyFit = newData;
    //   state.companyFit.confidence_indice = Math.min(
    //     state.companyFit.benefits.confidence_indice,
    //     state.companyFit.benchmarking.confidence_indice,
    //   );
    // },
    putCompanyDetails: (state, action) => {
      const cus = action.payload.data;
      const newData = [...state.companies.data];
      const index = newData.findIndex((x) => x.id === action.payload.id);
      Object.keys(cus).forEach((k) => {
        newData[index][action.payload.detailType][k] = cus[k];
      });
      state.companies.data = newData;
      state.companies.isLoading = false;
      state.companies.status = "success";
      state.activeCompany = newData[index];
      state.activeCompany.confidence_indice = Math.min(
        state.activeCompany.benefits.confidence_indice,
        state.activeCompany.benchmarking.confidence_indice,
      );
  },
  },
});

export const {
  getCustomers,
  setCustomersLoading,
  postCustomer,
  putCustomer,
  deleteCustomer,
  initCustomerStatus,
  initCompanyStatus,
  setCustomerError,
  setActiveCustomer,
  putCustomerDetails,
  getCompany,
  postCompany,
  getCompanies,
  putCompany,
  putCompanyDetails,
  setCompaniesLoading,
  setActiveCompany
} = review1Slice.actions;

export default review1Slice.reducer;

export const review1PostCustomer =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCustomersLoading());
      await APIService.post("/review-1/customers", data).then((response) => {
        dispatch(postCustomer(response.data.data.result));
      });
    } catch (e) {
      console.log(e);
      // dispatch(signInFailed("error on request"))
    }
  };

export const review1PostCompany =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCustomersLoading());
      await APIService.post("/review-1/companies", data).then((response) => {
        dispatch(postCompany(response.data.data.result));
      });
    } catch (e) {
      console.log(e);
      // dispatch(signInFailed("error on request"))
    }
  };

export const review1GetCustomers = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCustomersLoading());
    await APIService.get("/review-1/customers").then((response) => {
      dispatch(getCustomers(response.data.data.result));
    });
  } catch (e) {
    console.log(e);
    dispatch(setCustomerError());
  }
};

export const review1GetCompanies = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCompaniesLoading());
    await APIService.get("/review-1/companies").then((response) => {
      dispatch(getCompanies(response.data.data.result));
    });
  } catch (e) {
    console.log(e);
    // dispatch(setCustomerError());
  }
};

export const review1PutCustomer =
  (elementId: string, data: object) => async (dispatch: AppDispatch) => {
    try {
      console.log('PUT CUS', elementId, data);
      dispatch(setCustomersLoading());
      await APIService.put(`/review-1/customers/${elementId}`, data).then(
        (response) => {
          dispatch(putCustomer(response.data.data.result));
        }
      );
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

export const review1DeleteCustomer =
  (elementId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCustomersLoading());
      await APIService.delete(`/review-1/customers/${elementId}`).then(
        (response) => {
          dispatch(deleteCustomer({ id: elementId }));
        }
      );
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

export const review1DeleteCompany =
  (elementId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCustomersLoading());
      await APIService.delete(`/review-1/companies/${elementId}`).then(
        (response) => {
          dispatch(deleteCustomer({ id: elementId }));
        }
      );
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

export const review1GetCustomerById =
  (elementId: string) => async (dispatch: AppDispatch) => {
    dispatch(setActiveCustomer({ id: elementId }));
  };

export const review1GetCompanyById =
  (elementId: string) => async (dispatch: AppDispatch) => {
    dispatch(setActiveCompany({ id: elementId }));
  };

export const review1PutCustomerDetails =
  (detailType: string, elementId: string, data: object) => async (dispatch: AppDispatch) => {
    try {
      console.log('PUT CUS', elementId, detailType, data);
      dispatch(setCustomersLoading());
      await APIService.put(`/review-1/customers/${elementId}/${detailType}`, data).then(
        (response) => {
          dispatch(putCustomerDetails({ id: elementId, detailType, data, newData: response.data.new_data }));
        }
      );
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

  export const review1GetCompany = () => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.get("/review-1/companies").then((response) => {
        dispatch(getCompany(response.data.data.result));
      });
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

// export const review1PutCompany =
//   (elementId: string, data: object) => async (dispatch: AppDispatch) => {
//     try {
//       // dispatch(setCustomersLoading());
//       await APIService.put('/review-1/companies', data).then(
//         (response) => {
//           dispatch(putCompany(response.data.data.result));
//         }
//       );
//     } catch (e) {
//       console.log(e);
//       dispatch(setCustomerError());
//     }
//   };

export const review1PutCompany =
  (elementId: string, data: object) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setCustomersLoading());
      await APIService.put(`/review-1/companies/${elementId}`, data).then(
        (response) => {
          dispatch(putCompany(response.data.data.result));
        }
      );
    } catch (e) {
      console.log(e);
      // dispatch(setCError());
    }
  };

export const review1PutCompanyDetails =
  (detailType: string, elementId: string, data: object) => async (dispatch: AppDispatch) => {
    console.log(detailType, elementId);
    try {
      // dispatch(setCustomersLoading());
      await APIService.put(`/review-1/companies/${elementId}/${detailType}`, data).then(
        (response) => {
          dispatch(putCompanyDetails({ id: elementId, detailType, data }));
        }
      );
    } catch (e) {
      console.log(e);
      dispatch(setCustomerError());
    }
  };

export const getLocalToken = () => {
    return localStorage.getItem("token");
}

export const setLocalToken = (newToken: any) => {
    return localStorage.setItem("token", newToken)
}

export const getLocalRefreshToken = () => {
    return localStorage.getItem("refresh_token");
}
import { FC } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Alert
} from '@material-ui/core';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/use-auth';

import { faU, faS, faF } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const baseTabs = [
  {
    value: 'wm_the_ipop_tools',
  },
  {
    value: 'wm_the_ipop_reviews',
  },
  {
    value: 'wm_quick_actions_buttons',
  },
  {
    value: 'wm_best_practices'
  },
  // {
  //   value: 'wm_glossary'
  // }
];

interface  WelcomeDialogProps {
  open: boolean;
  onClose: () => void;
}

export const  WelcomeDialog: FC< WelcomeDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { welcomeMessageRead, welcomeMessageReadQuery } = useAuth();

  // TABS
  const [tabs] = useState(baseTabs);
  const [activeTab, setActiveTab] = useState('wm_the_ipop_tools');

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      // onClose={() => {setOpenConfirmationDialog(true)}}
      onClose={onClose}
      open={open}
      // TransitionProps={{
      //   onExited: () => formik.resetForm()
      // }}
      {...other}
    >
      <DialogTitle>
        Welcome to the IpOp Tools
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: 'background.default' }}>
        <Grid
          container
          spacing={2}
          my={2}
        >
          <Grid
            item
            xs={12}
          >
            <Tabs
              allowScrollButtonsMobile
              sx={{ mt: 0 }}
              value={tabs.findIndex((tab) => tab.value === activeTab)}
              variant="scrollable"
            >
              {tabs.map((option) => (
                <Tab
                  key={option.value}
                  label={t(option.value)}
                  onClick={() => setActiveTab(option.value)}
                />
              ))}
            </Tabs>
            <Divider />
            <Box sx={{ mt: 2 }}>
              { activeTab === 'wm_the_ipop_tools' && (
                <>
                  <h2>The IpOp Tools</h2>
                  <p>
                    Welcome to IpOp Tools, the only computer-aided project analysis software that helps you use the IpOp Model to evaluate your ideas and projects.The first letter of IpOp Model specific terms are always capitalized.
                  </p>
                  <p>
                    Using the software will help you to:
                  </p>
                  <ul>
                    <li>Identify the Unknowns of your project</li>
                    <li>Prepare 2 Action Plans (with if needed their respective cost):
                      <ul>
                        <li>One for reducing the Unknowns that are important for you</li>
                        <li>One for implementing your project to deliver the Definition of Success</li>
                      </ul>
                    </li>
                    <li>Iterate your analysis</li>
                    <li>Print a structured Opportunity Case that you can use to sell your idea to Investor or Decision-makers</li>
                  </ul>
                  <p>Return to this popup at any time by clicking on the information button on the top right of the screen.</p>
                  <Alert severity='warning'>
                    <b>Warning</b>
                    <p>This is a beta version of the latest release of IpOp Tools software, with a much-improveduser interface. Please do not hesitate to tell us about any bug or problem you may encounter. They will be fixed quickly. We apologise in advance for any inconvenience and thank you for your understanding.</p>
                    <p>As we are continuously improving the user experience, your feedback is also very valuable. It will help us improve the software. Please tell us what you like and/or what should be improved. We promise to react promptly.</p>
                  </Alert>
                </>
              )}
              { activeTab === 'wm_the_ipop_reviews' && (
                <>
                  <h2>The IpOp Reviews</h2>
                  <p>The IpOp Model contains 4 Reviews that can be found in the left menu:</p>
                  <ol>
                    <li><b>IpOp Review 1</b> checks if there is a <b>hope</b> that the solution is suitable for each Customer and that the project or opportunity is suitable for the Investoror Decision-maker.</li>
                    <li><b>IpOp Review 2</b> verifies that the project is <b>worthwhile</b>.</li>
                    <li><b>IpOp Review 3</b> verifies that the project can accommodate the <b>Influencers</b> (Factors, including Risks, and Stakeholders).</li>
                    <li><b>IpOp Review 4</b> establishes <b>who does what and which Resources</b> are required. It produces <b>2 Action Plans</b> (one to reduce the important Unknowns and one to deliver the Definition of Success)</li>
                  </ol>
                  <p>Within any IpOp Review use the top navigation tabs to address each component of that IpOp Review</p>
                </>
              )}
              { activeTab === 'wm_quick_actions_buttons' && (
                <>
                  <h2>Quick Actions Buttons</h2>
                  <p>As you go through the IpOp Model, you will naturally think of ideas or things that you should remember.</p>
                  <p>To capture these thoughts on the go, use the quick action buttons identified with a capital letter at the top of the screen. Each button groups all your ideas in lists for later access.</p>
                  <p>Each button will open a different popup window for you to enter that idea or thought. You can :</p>
                  <ul>
                    <li style={{listStyle: 'none'}}>- just enter the basic idea with the understanding that you can later go into more details</li>
                    <li style={{listStyle: 'none'}}>- immediately address all the detail in the popup window</li>
                    <li style={{listStyle: 'none'}}>- do a mix of both</li>
                  </ul>

                  {/* UNKNOWNS */}
                  <ul>
                    <li><h3><span style={{color: '#ec4c47'}}><FontAwesomeIcon icon={faU} /></span> for Unknowns</h3></li>
                  </ul>
                  <p>Not being aware of what you do not know is your biggest danger.</p>
                  <p>Every project contains uncertainty. This uncertainty results from:</p>
                  <ul>
                    <li style={{listStyle: 'none'}}>- events that have a probability of occurrence (Factors such as Risks)</li>
                    <li style={{listStyle: 'none'}}>- a lack of information (Unknowns). Unknowns are an expression of your ignorance, which has no probability.</li>
                  </ul>
                  <p>Mapping Unknowns is a key element of the IpOp Model. Identifying them will also allow you to assess the Resources you need to reduce the important Unknown. Every time you are missing information or when you are not sureabout something, click on the “U” button (U for Unknown) on the top of the screen. </p>
                  <p>You can find the list of all your Unknowns for later detailed analysis in the left Unknowns menu. In this list the far right “...” gives the opportunity to revisit and complete the details for each Unknown.</p>

                  {/* MOVES */}
                  <ul>
                    <li><h3><span style={{color: '#27AB6E'}}><FontAwesomeIcon icon={faS} /></span> for Stakeholders</h3></li>
                  </ul>
                  <p>Stakeholders are people to whom you are accountable and who have power over you.</p>
                  <p>Every time you think of something related to your stakeholders, click on the S button to open the corresponding popup window. This will ensure you enrich your knowledge about each Stakeholder. The more you know the people who have power over you, the better off you are...</p>
                  <p>Stakeholders can include civil society, unions, the environment, your management, your family, etc.</p>
                  <p>You can find a list of all your Stakeholders for later detailed analysis in the “Influencers” left menu (Review3). In this list,the far right “...” gives you the opportunity to revisit and complete the details for each Stakeholder.</p>

                  {/* FACTORS */}
                  <ul>
                    <li><h3><span style={{color: '#5e6af6'}}><FontAwesomeIcon icon={faF} /></span> for Factors</h3></li>
                  </ul>
                  <p>Factors are event-related parameters that can influence your project.</p>
                  <p>Negative Factors, such as Risks or Obstacles, are hurdles that will slow you down. Positive Factors act as Boosters for your projects. Factors can include the competition, supply chain challenges, price or currency fluctuations, new technological developments, etc.</p>
                  <p>Every time you think of a Factor, click on the F button to open the corresponding popup window. This will ensure you do not forget factors.</p>
                  <p>You can find a list of all the Factors you have identified for later detailed analysis in the “Influencers” left menu(Review3). In this list, the far right “...” gives you the opportunity to revisit and complete the details for each Factor. </p>
                </>
              )}
              { activeTab === 'wm_best_practices' && (
                <>
                  <h2>Best Practices</h2>
                  <p>In order to take full advantage of the software, here are a few “features” to help you out in your journey:</p>

                  {/* ITERATION */}
                  <ul>
                    <li><h3>Iteration</h3></li>
                  </ul>
                  <p>Revisit again and again... and again</p>
                  <p>Start with Quick and Dirty data entry to later refine the reliability of the information you enter. To monitor your progress through these iterations, you should keep track of the reliability of your input: for each pageyou should use the colored “confidence” dotsto express the level of reliability of the content of the page.</p>
                  <p>If you don’t have the answer to a question, or are feeling uninspired by a specific field, don’t stay stuck on it and just enter a “?”. The nice thing of using a software is that it is easy to come back whenyou feel ready. The tool is intended for you to move back and forth, and sometimes answers further down the line will make you rethink answers you have already provided. To make sure you do not forget to deal with “?”, you can print a Report listing all the fields containing a “?”.</p>
                  <p>If a field is not relevant for you, just type N/A (until the next release that will add an icon to strikethroughfields that are not relevant for your specific project).</p>

                  {/* COMMENTING */}
                  <ul>
                    <li><h3>Commenting</h3></li>
                  </ul>
                  <p>Add comments for your own use or for sharing</p>
                  <p>For each page you enter comments related to the content of that page. The comment can be :</p>
                  <ul>
                    <li style={{listStyle: 'none'}}>- only for your eyes (i.e. to remember something about thispage)</li>
                    <li style={{listStyle: 'none'}}>- shared with the rest of the team</li>
                  </ul>
                  <p>The shared comments are not for chat purposes but an opportunity to remotely exchange comments. If you have a coach, the coach can for instance remotely give you advice.</p>
                  <p>Click on the Comment button in the upper right to enter a commentor see those already there.</p>

                  {/* DOCUMENTING */}
                  <ul>
                    <li><h3>Documenting</h3></li>
                  </ul>
                  <p>Keep track of the source of what you enter</p>
                  <p>For each page you can upload files that support the content of that page. Files can be supporting documents,spreadsheets, images, etc.</p>
                  <p>Click on the Upload button in the upper right to upload a file or see those already there.</p>

                  {/* TEAMWORK */}
                  <ul>
                    <li><h3>Teamwork</h3></li>
                  </ul>
                  <p>Teamwork makes the dream work</p>
                  <p>A project is by nature a complex undertaking. Doing it alone rarely delivers the best results. Teamwork usually works better.</p>
                  <p>The tool includes multiple features to enable team collaboration, such as leaving comments and uploading documents. Make sure you use them thoroughly to optimize teamwork.</p>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="contained"
        >
          {t('close')}
        </Button>
        {welcomeMessageRead === false && (
          <Button
            color="success"
            size="small"
            // disabled={bugReportIsLoading}
            // disabled={!(formik.isValid && formik.dirty) || bugReport.bugReportIsLoading}
            // disabled={(bugReport.bugReportIsLoading || !bugReport.isEditabled)}
            onClick={() => { welcomeMessageReadQuery() }}
            variant="contained"
          >
            {t('dont_show_again')}
          </Button>
        )}
      </DialogActions>
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

 WelcomeDialog.defaultProps = {
  open: false
};

 WelcomeDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};

import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  bugReportDialogOpen: false,
  bugReportIsLoading: false,
  bugReportStatus: null,
  bugReportNextAction: null,
  bugReportId: null,
  message: '',
  isEditabled: true,
  isDuplicated: false,
};

export const bugReport = createSlice({
  name: "bugReport",
  initialState,
  reducers: {
    openBugReportDialog: (state, action) => {
      state.bugReportDialogOpen = true;
      state.message = action.payload.element.message;
      // Other
      state.isEditabled = action.payload.isEditabled;
      state.isDuplicated = action.payload.isDuplicated;
    },
    closeBugReportDialog: (state) => {
      state.bugReportDialogOpen = false;
    },
    loadingBugReport: (state) => {
      state.bugReportIsLoading = true;
    },
    resetBugReport: (state, action) => {
      state.bugReportIsLoading = false;
      state.bugReportStatus = 'success';
      state.bugReportNextAction = action.payload;
    },
    resetBugReportStatus: (state) => {
      state.bugReportStatus = null;
    }
  },
});

export const {
  openBugReportDialog,
  closeBugReportDialog,
  loadingBugReport,
  resetBugReport,
  resetBugReportStatus
} = bugReport.actions;

export default bugReport.reducer;

export const bugReportPost = (nextAction, data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingBugReport());
    await APIService.post("/bugs", data).then((response) => {
      dispatch(resetBugReport(nextAction));
    });
  } catch (e) {
    // dispatch(setCustomerError());
  }
};

import { FC, useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Divider} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { InputField } from '../input-field';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { intellectualPropertyPost, resetIntellectualPropertyStatus } from '../../slices/intellectualPropertySlice';
import { review4GetAll } from '../../slices/review4Slice';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';

interface IntellectualPropertyDialogProps {
  open: boolean;
  onClose: () => void;
}

export const IntellectualPropertyDialog: FC<IntellectualPropertyDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { intellectualPropertyStatus, intellectualPropertyNextAction } = useSelector((state: RootState) => state.intellectualProperty);

  const intellectualProperty = useSelector((state: RootState) => state.intellectualProperty);
  const dispatch = useDispatch();

  let initialValues = {
    description: '',
    uniqueFeature: '',
    currentBestMode: '',
    persons: '',
    priorArtDescription: '',
    closestPublication: '',
    submit: null
  };

  if ((intellectualProperty.intellectualPropertyId !== null && intellectualProperty.intellectualPropertyId !== undefined) || intellectualProperty.isDuplicated === true) {
    initialValues.description = intellectualProperty.description;
    initialValues.uniqueFeature = intellectualProperty.uniqueFeature;
    initialValues.currentBestMode = intellectualProperty.currentBestMode;
    initialValues.persons = intellectualProperty.persons;
    initialValues.priorArtDescription = intellectualProperty.priorArtDescription;
    initialValues.closestPublication = intellectualProperty.closestPublication;
  }

  useEffect(() => {
    if (intellectualPropertyStatus === 'success') {
      toast.success('IntellectualProperty created');
      dispatch(review4GetAll());
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      dispatch(resetIntellectualPropertyStatus());
      if (intellectualPropertyNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: intellectualPropertyStatus });
      formik.setSubmitting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intellectualPropertyStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      description: Yup.string().min(3).max(255).required('Description is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleSubmitAndQuit = () => {
    dispatch(intellectualPropertyPost('close', formik.values, intellectualProperty.intellectualPropertyId));
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={() => {setOpenConfirmationDialog(true)}}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle>
        Timestamp intellectual property relevant information
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          my={2}
        >
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.description && formik.errors.description)}
              fullWidth
              helperText={formik.touched.description && formik.errors.description}
              label={t('description')}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              multiline
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.uniqueFeature && formik.errors.uniqueFeature)}
              fullWidth
              helperText={formik.touched.uniqueFeature && formik.errors.uniqueFeature}
              label={t('unique_feature')}
              name="uniqueFeature"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.uniqueFeature}
              multiline
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.currentBestMode && formik.errors.currentBestMode)}
              fullWidth
              helperText={formik.touched.currentBestMode && formik.errors.currentBestMode}
              label={t('current_best_mode')}
              name="currentBestMode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.currentBestMode}
              multiline
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.persons && formik.errors.persons)}
              fullWidth
              helperText={formik.touched.persons && formik.errors.persons}
              label={t('persons')}
              name="persons"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.persons}
              multiline
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.priorArtDescription && formik.errors.priorArtDescription)}
              fullWidth
              helperText={formik.touched.priorArtDescription && formik.errors.priorArtDescription}
              label={t('prior_art_description')}
              name="priorArtDescription"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.priorArtDescription}
              multiline
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.closestPublication && formik.errors.closestPublication)}
              fullWidth
              helperText={formik.touched.closestPublication && formik.errors.closestPublication}
              label={(t('closest_publication'))}
              name="closestPublication"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.closestPublication}
              multiline
            />
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(intellectualProperty.intellectualPropertyIsLoading || !intellectualProperty.isEditabled)}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
        >
          {(intellectualProperty.intellectualPropertyId !== null && intellectualProperty.intellectualPropertyId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions>
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

IntellectualPropertyDialog.defaultProps = {
  open: false
};

IntellectualPropertyDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};

import type { FC } from 'react';
import { Box, Container, Typography } from '@material-ui/core';

export const Footer: FC = () => (
  <div>
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        backgroundColor: 'background.paper',
        flexDirection: {
          sm: 'row',
          xs: 'column'
        },
        py: 3,
        '& a': {
          mt: {
            sm: 0,
            xs: 1
          },
          '&:not(:last-child)': {
            mr: {
              sm: 5,
              xs: 0
            }
          }
        }
      }}
    >
      <Typography
        color="textSecondary"
        variant="caption"
      >
        © 2022 Ipop Tools
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {/* {links.map((link) => (
        <Link
          color="textSecondary"
          href={link.href}
          key={link.label}
          target="_blank"
          underline="none"
          variant="body2"
        >
          {link.label}
        </Link>
      ))} */}
    </Container>
  </div>
);

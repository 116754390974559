import axios from "axios";
import { getLocalRefreshToken, getLocalToken, setLocalToken } from "./token.service";
import jwt from 'jsonwebtoken'


// const BASE_URL = 'http://localhost:5300';
// const BASE_URL = 'http://35.180.156.147:5000';
const BASE_URL = 'https://demo-ws.ipoptools.com';

// MC
// const BASE_URL = "http://13.38.86.135";

const instance = axios.create({
    // baseURL: 'http://localhost:5300',
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
})

instance.interceptors.request.use(
    (config) => {
        const token = getLocalToken();
        var decodedToken=jwt.decode(token, {complete: true});
        var dateNow = new Date();

        if(decodedToken.exp < dateNow.getTime()) {
        } else {
        }

        if(token) {
            if(config.headers) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (res) => {
        return res;
    }, async (err) => {
        const originalConfig = err.config;

        if(originalConfig.url !== "/users/signin" && err.response) {
            if((err.response.status === 401 || err.response.status === 422) && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const refreshToken = getLocalRefreshToken();
                    // const rs = await axios.get("http://localhost:5300/users/refresh-token", {
                    const rs = await axios.get(`${BASE_URL}/users/refresh-token`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    })

                    const newToken = rs.data["data"]["token"];
                    setLocalToken(newToken);

                    return instance(originalConfig)
                } catch(_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
)

export default instance;
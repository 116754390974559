import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Box, Button, Divider, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { useSettings } from '../contexts/settings-context';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { ProjectPopover } from './project-popover';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../store';

import { openUnknownDialog, closeUnknownDialog } from '../slices/unknownSlice';
import { openStakeholderDialog, closeStakeholderDialog} from '../slices/stakeholderSlice';
import { openFactorDialog, closeFactorDialog } from '../slices/factorSlice';
import { openMoveDialog, closeMoveDialog } from '../slices/moveSlice';
import { openIntellectualPropertyDialog, closeIntellectualPropertyDialog } from '../slices/intellectualPropertySlice';

import { FactorDialog } from '../components/quick-actions/factor-dialog';
import { MoveDialog } from '../components/quick-actions/move-dialog';
import { UnknownDialog } from '../components/quick-actions/unknown-dialog';
import { StakeholderDialog } from '../components/quick-actions/stakeholder/stakeholder-dialog';
import { IntellectualPropertyDialog } from '../components/quick-actions/intellectual-property-dialog';
import { WelcomeDialog } from '../components/dialogs/welcome-dialog';

const organizations = [
  {
    id: '6039124832',
    name: 'ACME LTD.'
  },
  {
    id: '3828312374',
    name: 'Division LTD.'
  }
];

export const DashboardNavbar: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');
  const [rtlDirection, setRtlDirection] = useState(settings.direction === 'rtl');
  const [currentOrganization, setCurrentOrganization] = useState(organizations[0]);
  const [showInfo, setShowInfo] = useState(false);

  const dispatch = useDispatch();

  const { unknownDialogOpen } = useSelector((state: RootState) => state.unknown);
  const { stakeholderDialogOpen } = useSelector((state: RootState) => state.stakeholder);
  const { factorDialogOpen } = useSelector((state: RootState) => state.factor);
  const { moveDialogOpen } = useSelector((state: RootState) => state.move);
  const { intellectualPropertyDialogOpen } = useSelector((state: RootState) => state.intellectualProperty);
  // const [unknownDialogOpen, setUnknownDialogOpen] = useState(false);

  const handleLanguageChange = (language: 'en' | 'fr'): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language
    });
    toast.success(t('toast_language_changed'));
  };

  const handleSwitchTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light'
    });

    setDarkMode(settings.theme === 'light');
  };

  const handleSwitchDirection = (): void => {
    saveSettings({
      ...settings,
      direction: settings.direction === 'ltr' ? 'rtl' : 'ltr'
    });

    setRtlDirection(settings.direction === 'rtl');
  };

  const handleOrganizationChange = (organizationId: string): void => {
    const newOrganization = organizations.find(
      (organization) => organization.id === organizationId
    );

    if (!newOrganization) {
      return;
    }

    setCurrentOrganization(newOrganization);
  };

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'background.paper' }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 90,
          width: 'calc(100% - 250px)',
          left: 250,
          px: 3,
          py: 1
        }}
      >
        {/* <Box
          component={RouterLink}
          to="/projects"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img src='./static/ipop-logo.png' width={100} />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        /> */}
        <ProjectPopover
          currentOrganization={currentOrganization}
          onOrganizationChange={handleOrganizationChange}
          organizations={organizations}
          sx={{
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 1
          }}
        />
        {/* Quick Actions */}
        <Tooltip title={t('info_short_text_8')} placement={'bottom'} arrow>
          <IconButton
            color="inherit"
            onClick={() => dispatch(openUnknownDialog({element: {}, isEditabled: true, isDuplicated: false}))}
            sx={{
              mx: 0.5,
              display: {
                md: 'inline-flex',
                xs: 'none'
              },
              backgroundColor: 'background.default',
              color: 'error.main',
              boxShadow: 7,
              fontSize: '18px'
            }}
          >
            {/* <FontAwesomeIcon icon={faU} size="xs" /> */}
            <b>U</b>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('info_short_text_11')} placement={'bottom'} arrow>
          <IconButton
            color="inherit"
            onClick={() => dispatch(openMoveDialog({element: {}, isEditabled: true, isDuplicated: false}))}
            sx={{
              mx: 0.5,
              display: {
                md: 'inline-flex',
                xs: 'none'
              },
              backgroundColor: 'background.default',
              color: 'success.main',
              boxShadow: 7,
              fontSize: '18px',
              fontWeight: 500
            }}
          >
            <b>M</b>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('info_short_text_9')} placement={'bottom'} arrow>
          <IconButton
            color="inherit"
            onClick={() => dispatch(openStakeholderDialog({element: {}, isEditabled: true, isDuplicated: false}))}
            sx={{
              mx: 0.5,
              display: {
                md: 'inline-flex',
                xs: 'none'
              },
              backgroundColor: 'background.default',
              color: '#8F16C8',
              boxShadow: 7,
              fontSize: '18px'
            }}
          >
            {/* <FontAwesomeIcon icon={faF} size="xs" /> */}
            <b>S</b>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('info_short_text_10')} placement={'bottom'} arrow>
          <IconButton
            color="inherit"
            onClick={() => dispatch(openFactorDialog({element: {}, isEditabled: true, isDuplicated: false}))}
            sx={{
              mx: 0.5,
              display: {
                md: 'inline-flex',
                xs: 'none'
              },
              backgroundColor: 'background.default',
              color: 'warning.main',
              boxShadow: 7,
              fontSize: '18px'
            }}
          >
            {/* <FontAwesomeIcon icon={faM} size="xs" /> */}
            <b>F</b>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('info_short_text_12')} placement={'bottom'} arrow>
          <IconButton
            color="inherit"
            onClick={() => dispatch(openIntellectualPropertyDialog({element: {}, isEditabled: true, isDuplicated: false}))}
            sx={{
              mx: 0.5,
              display: {
                md: 'inline-flex',
                xs: 'none'
              },
              backgroundColor: 'background.default',
              color: '#858585',
              boxShadow: 7,
              fontSize: '18px'
            }}
          >
            {/* <FontAwesomeIcon icon={faBrain} size="xs" /> */}
            <b>IP</b>
          </IconButton>
        </Tooltip>

        {/* End Quick Actions */}
        
        <DashboardNavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          endIcon={(
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 0.5,
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none'
              }}
            />
          )}
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex'
            }
          }}
          variant="text"
        >
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {/* <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          sx={{
            display: {
              md: 'inline-flex',
              xs: 'none'
            }
          }}
        /> */}
        <IconButton
          color="inherit"
          onClick={() => setShowInfo(!showInfo)}
          sx={{
            mx: 0.5,
            display: {
              md: 'inline-flex',
              xs: 'none'
            },
            color: 'primary.main'
          }}
        >
          <FontAwesomeIcon icon={faCircleQuestion as IconProp} size="xs" />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 0.5,
            display: {
              md: 'inline-flex',
              xs: 'none'
            },
            color: 'primary.main'
          }}
        >
          {
            darkMode
              ? <SunIcon />
              : <MoonIcon />
          }
        </IconButton>
        {/* <NotificationsPopover sx={{ mr: 2 }} /> */}
        {/* TODO: Add New Project button */}
        {/* <Button
          style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
          size="small"
          startIcon={<PlusIcon />}
          variant="contained"
          component={RouterLink}
          to="/projects/new"
        >
          {t('navbar_new_project')}
        </Button> */}
        <AccountPopover
          currentOrganization={currentOrganization}
          darkMode={darkMode}
          onLanguageChange={handleLanguageChange}
          onOrganizationChange={handleOrganizationChange}
          onSwitchDirection={handleSwitchDirection}
          onSwitchTheme={handleSwitchTheme}
          organizations={organizations}
          rtlDirection={rtlDirection}
        />
        <UnknownDialog open={unknownDialogOpen} onClose={() => dispatch(closeUnknownDialog())} />
        <StakeholderDialog open={stakeholderDialogOpen} onClose={() => dispatch(closeStakeholderDialog())} />
        <FactorDialog open={factorDialogOpen} onClose={() => dispatch(closeFactorDialog())} />
        <MoveDialog open={moveDialogOpen} onClose={() => dispatch(closeMoveDialog())} />
        <IntellectualPropertyDialog open={intellectualPropertyDialogOpen} onClose={() => dispatch(closeIntellectualPropertyDialog())} />
        <WelcomeDialog open={showInfo} onClose={() => setShowInfo(false)} />
      </Toolbar>
    </AppBar>
  );
};

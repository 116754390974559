import { useEffect } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Card, CardContent, Container, Grid, Toolbar } from '@material-ui/core';
import { LoginJwt } from '../components/auth/login-jwt';
import gtm from '../lib/gtm';
import { useTranslation } from 'react-i18next';

export const Login: FC = () => {
  // const { method } = useAuth() as any;
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('login')} | Ipop Tools</title>
      </Helmet>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Container maxWidth="md">
          <Toolbar
            disableGutters
            sx={{ height: 64 }}
          >
            <RouterLink to="/">
              {/* <Logo emblemOnly variant={settings.theme === 'dark' ? 'light' : 'dark'} /> */}
              <img src='/static/ipop-logo.png' width={100} alt='' />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid
              container
              spacing={6}
            >
              {/* <Grid
                item
                md={6}
                sx={{
                  display: {
                    md: 'block',
                    xs: 'none'
                  }
                }}
                xs={12}
              >
                <ProductFeatures />
              </Grid> */}
              <Grid
                item
                md={9}
                xs={12}
              >
                <Card
                  sx={{ backgroundColor: 'background.default' }}
                  elevation={0}
                >
                  <CardContent>
                    {/* {method === 'Amplify' && <LoginAmplify />}
                    {method === 'Auth0' && <LoginAuth0 />}
                    {method === 'Firebase' && <LoginFirebase />}
                    {method === 'JWT' && <LoginJwt />} */}
                    <LoginJwt />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

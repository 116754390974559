import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { Account } from './containers/account/account';
import { DashboardLayout } from './containers/dashboard-layout';
import { Organization } from './containers/organization/organization';
import { Project } from './containers/project/project';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
// const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(
  () => import('./containers/login').then((module) => ({ default: module.Login }))
));
const PasswordRecovery = Loadable(lazy(
  () => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))
));
const PasswordReset = Loadable(lazy(
  () => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))
));
const Register = Loadable(lazy(
  () => import('./containers/register').then((module) => ({ default: module.Register }))
));
const VerifyCode = Loadable(lazy(
  () => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))
));

// Dashboard pages
const Dashboard = Loadable(lazy(
  () => import('./components/dashboard/dashboard').then((module) => ({ default: module.Dashboard }))
));

// const ReportsOverview = Loadable(lazy(
//   () => import('./containers/reports-overview').then((module) => ({ default: module.ReportsOverview }))
// ));
// const ReportsSales = Loadable(lazy(
//   () => import('./containers/reports-sales').then((module) => ({ default: module.ReportsSales }))
// ));

// Validation pages
const ValidationContainer = Loadable(lazy(
  () => import('./containers/empty-container').then((module) => ({ default: module.EmptyContainer }))
));
const ValidateCode = Loadable(lazy(
  () => import('./containers/validate-invite-code').then((module) => ({ default: module.ValidateInviteCode }))
));
const ValidateAnswer = Loadable(lazy(
  () => import('./containers/validate-answer').then((module) => ({ default: module.ValidateAnswer }))
));

const AccountGeneral = Loadable(lazy(
  () => import('./containers/account/account-general').then((module) => ({ default: module.AccountGeneral }))
));
const AccountNotifications = Loadable(lazy(
  () => import('./containers/account/account-notifications').then((module) => ({ default: module.AccountNotifications }))
));

const OrganizationBilling = Loadable(lazy(
  () => import('./containers/organization/organization-billing').then((module) => ({ default: module.OrganizationBilling }))
));
const OrganizationGeneral = Loadable(lazy(
  () => import('./containers/organization/organization-general').then((module) => ({ default: module.OrganizationGeneral }))
));
const OrganizationTeam = Loadable(lazy(
  () => import('./containers/organization/organization-team').then((module) => ({ default: module.OrganizationTeam }))
));

// Project pages
const ProjectGeneral = Loadable(lazy(
  () => import('./containers/project/project-general').then((module) => ({ default: module.ProjectGeneral }))
));
const ProjectTeam = Loadable(lazy(
  () => import('./containers/project/project-team').then((module) => ({ default: module.ProjectTeam }))
));

const Projects = Loadable(lazy(
  () => import('./containers/project/projects').then((module) => ({ default: module.Projects }))
));
const ProjectNew = Loadable(lazy(
  () => import('./containers/project/project-new').then((module) => ({ default: module.ProjectNew }))
));

// Review 1 pages
const Review1Index = Loadable(lazy(
  () => import('./containers/review-1/review-1-index').then((module) => ({ default: module.Review1Index }))
));
const Review1Customers = Loadable(lazy(
  () => import('./containers/review-1/review-1-customers').then((module) => ({ default: module.Customers }))
));
const Review1CustomerFit = Loadable(lazy(
  () => import('./containers/review-1/review-1-customer-fit').then((module) => ({ default: module.CustomerFit }))
));
const Review1Companies = Loadable(lazy(
  () => import('./containers/review-1/review-1-companies').then((module) => ({ default: module.Companies }))
));
const Review1CompanyFit = Loadable(lazy(
  () => import('./containers/review-1/review-1-company-fit').then((module) => ({ default: module.CompanyFit }))
));

// Review 2 pages
const Review2 = Loadable(lazy(
  () => import('./containers/review-2/review-2').then((module) => ({ default: module.Review2 }))
));
const Review2Index = Loadable(lazy(
  () => import('./containers/review-2/review-2-index').then((module) => ({ default: module.Review2Index }))
));

// Review 3 pages
const Review3 = Loadable(lazy(
  () => import('./containers/review-3/review-3').then((module) => ({ default: module.Review3 }))
));
const Review3Index = Loadable(lazy(
  () => import('./containers/review-3/review-3-index').then((module) => ({ default: module.Review3Index }))
));

// Review 4 pages
const Review4 = Loadable(lazy(
  () => import('./containers/review-4/review-4').then((module) => ({ default: module.Review4 }))
));
const Review4Index = Loadable(lazy(
  () => import('./containers/review-4/review-4-index').then((module) => ({ default: module.Review4Index }))
));

// Review 4 pages
const Unknowns = Loadable(lazy(
  () => import('./containers/unknowns/unknowns').then((module) => ({ default: module.Unknowns }))
));

// Reports
const Reports = Loadable(lazy(
  () => import('./containers/reports/reports').then((module) => ({ default: module.Reports }))
));

export const routes: PartialRouteObject[] = [
  // {
  //   path: '/',
  //   element: (
  //     <MainLayout>
  //       <Home />
  //     </MainLayout>
  //   )
  // },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/reports"
            replace
          />
        )
      },
      // {
      //   path: 'reports',
      //   element: <Reports />,
      //   children: [
      //     {
      //       path: '/',
      //       element: <ReportsOverview />
      //     },
      //     {
      //       path: 'sales',
      //       element: <ReportsSales />
      //     }
      //   ]
      // },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: '/',
            element: <AccountGeneral />
          },
          {
            path: 'notifications',
            element: <AccountNotifications />
          },
          {
            path: 'team',
            element: <OrganizationTeam />
          }
        ]
      },
      {
        path: 'organization',
        element: <Organization />,
        children: [
          {
            path: '/',
            element: <OrganizationGeneral />
          },
          {
            path: '/team',
            element: <OrganizationTeam />
          },
          {
            path: '/billing',
            element: <OrganizationBilling />
          }
        ]
      },
    ]
  },
  {
    path: '*',
    // element: <NotFound />
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  }
];

export const getRoutes = (accessRights: object) => {
  const localRoutes: PartialRouteObject[] = [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: 'verify-code',
      element: (
        <GuestGuard>
          <VerifyCode />
        </GuestGuard>
      )
    },
    {
      path: 'password-recovery',
      element: (
        <GuestGuard>
          <PasswordRecovery />
        </GuestGuard>
      )
    },
    {
      path: 'password-reset',
      element: (
        <GuestGuard>
          <PasswordReset />
        </GuestGuard>
      )
    },
    {
      path: '*',
      // element: <NotFound />
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'validation',
      element: <ValidationContainer />,
      children: [
        {
          path: '/validate-code',
          element: <ValidateCode />
        },
        {
          path: 'validate-answers',
          element: <ValidateAnswer />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: (
            <Dashboard />
          )
        },
        // {
        //   path: 'reports',
        //   element: <Reports />,
        //   children: [
        //     {
        //       path: '/',
        //       element: <ReportsOverview />
        //     },
        //     {
        //       path: 'sales',
        //       element: <ReportsSales />
        //     }
        //   ]
        // },
        {
          path: 'account',
          element: <Account />,
          children: [
            {
              path: '/',
              element: <AccountGeneral />
            },
            {
              path: 'notifications',
              element: <AccountNotifications />
            },
            {
              path: 'team',
              element: <OrganizationTeam />
            }
          ]
        },
        // {
        //   path: 'organization',
        //   element: <Organization />,
        //   children: [
        //     {
        //       path: '/',
        //       element: <OrganizationGeneral />
        //     },
        //     {
        //       path: '/team',
        //       element: <OrganizationTeam />
        //     },
        //     {
        //       path: '/billing',
        //       element: <OrganizationBilling />
        //     }
        //   ]
        // },
      ]
    }
  ];

  if (accessRights) {
    if (accessRights['project_admin_space'] === 1) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'organization',
          element: <Organization />,
          children: [
            {
              path: '/',
              element: <OrganizationGeneral />
            },
            {
              path: '/team',
              element: <OrganizationTeam />
            },
            {
              path: '/billing',
              element: <OrganizationBilling />
            }
          ]
        },
        {
          path: 'project',
          element: <Project />,
          children: [
            {
              path: '/',
              element: <ProjectGeneral />
            },
            {
              path: '/team',
              element: <ProjectTeam />
            },
            // {
            //   path: '/billing',
            //   element: <OrganizationBilling />
            // }
          ]
        },
  
      );
    }

    if (accessRights['review_1'] === 1 || accessRights['review_1'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'review-1',
          // element: <Review1Index />,
          children: [
            {
              path: '/',
              element: <Review1Index />
            },
            {
              path: '/customers',
              element: <Review1Customers />
            },
            {
              path: '/companies',
              element: <Review1Companies />
            },
            {
              path: '/customer-fit/:id',
              element: <Review1CustomerFit />
            },
            {
              path: '/company-fit/:id',
              element: <Review1CompanyFit />
            },
          ]
        }
      )
    }

    if (accessRights['review_2'] === 1 || accessRights['review_2'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'review-2',
          // element: <Review2 />,
          children: [
            {
              path: '/',
              element: <Review2 />
            },
            {
              path: '/definition-of-success',
              element: <Review2 />
            },
            {
              path: '/definition-of-failure',
              element: <Review2 />
            },
            {
              path: '/activity-chain',
              element: <Review2 />
            },
            {
              path: '/economic-model',
              element: <Review2 />
            },
          ]
        }
      )
    }

    if (accessRights['review_3'] === 1 || accessRights['review_3'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'review-3',
          // element: <Review3 />
          children: [
            {
              path: '/',
              element: <Review3 />
            },
            {
              path: '/factors',
              element: <Review3 />
            },
            {
              path: '/stakeholders',
              element: <Review3 />
            },
          ]
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'review-4',
          // element: <Review4 />
          children: [
            {
              path: '/',
              element: <Review4 />
            },
            {
              path: '/moves',
              element: <Review4 />
            },
            {
              path: '/moves2',
              element: <Review4 />
            },
            {
              path: '/intellectual-properties',
              element: <Review4 />
            },
          ]
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'unknowns',
          element: <Unknowns />
          // children: [
          //   {
          //     path: '/economic-model',
          //     element: <Review2EconomicModel />
          //   },
          // ]
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localRoutes[localRoutes.length-1]['children'].push(
        {
          path: 'reports',
          element: <Reports />
          // children: [
          //   {
          //     path: '/economic-model',
          //     element: <Review2EconomicModel />
          //   },
          // ]
        }
      )
    }

    localRoutes[localRoutes.length-1]['children'].push(
      {
        path: 'projects',
        // element: <Organization />,
        children: [
          {
            path: '/',
            element: <Projects />
          },
          {
            path: '/new',
            element: <ProjectNew />
          },
          // {
          //   path: '/billing',
          //   element: <OrganizationBilling />
          // }
        ]
      },
    );
  }

  return localRoutes;
};

import { FC } from 'react';
import {
  Card,
  CardContent,
  Grid
  } from '@material-ui/core';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSlice';

export const WizardDetails: FC = () => {
  const { t } = useTranslation();

  const move = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
              <div>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      // error={Boolean(formik.touched.descriptionOfTheMove && formik.errors.descriptionOfTheMove)}
                      fullWidth
                      // helperText={formik.touched.descriptionOfTheMove && formik.errors.descriptionOfTheMove}
                      label={t('description_of_the_move')}
                      name="descriptionOfTheMove"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'descriptionOfTheMove', value: e.target.value}))}
                      value={move.descriptionOfTheMove}
                      placeholder=""
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      // error={Boolean(formik.touched.kpi && formik.errors.kpi)}
                      fullWidth
                      // helperText={formik.touched.kpi && formik.errors.kpi}
                      label={t('kpi')}
                      name="kpi"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'kpi', value: e.target.value}))}
                      value={move.kpi}
                      placeholder=""
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      // error={Boolean(formik.touched.goalDate && formik.errors.goalDate)}
                      fullWidth
                      // helperText={formik.touched.goalDate && formik.errors.goalDate}
                      label={t('goal_date')}
                      name="goalDate"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'goalDate', value: e.target.value}))}
                      value={move.goalDate}
                      placeholder=""
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <InputField
                      // error={Boolean(formik.touched.descriptionOfTheGoal && formik.errors.descriptionOfTheGoal)}
                      fullWidth
                      // helperText={formik.touched.descriptionOfTheGoal && formik.errors.descriptionOfTheGoal}
                      label={t('description_of_the_goal')}
                      name="descriptionOfTheGoal"
                      // onBlur={formik.handleBlur}
                      onChange={(e) => dispatch(updateField({field: 'descriptionOfTheGoal', value: e.target.value}))}
                      value={move.descriptionOfTheGoal}
                      placeholder=""
                      multiline
                      rows={2}
                    />
                  </Grid>

                  {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
                </Grid>
              </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
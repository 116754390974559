import axios from 'axios';
// import fs from 'fs';

export const postDocumentToS3 = async (url: string, file: File) => {

  const config = {
    headers: {
      'Content-Type': file.type
    }
  };

  const resp = await axios.put(url, file, config);
  return resp;
};

import { TableBody, TableRow } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"


const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
      background: "rgb(235,235,235)"
  })
})

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const DraggableComponent = (id, index, tableIsDraggabled = true) => (props) => {
  return (
      <Draggable draggableId={id} index={index} enableUserSelectHack={false} handle=".just-input" isDragDisabled={!tableIsDraggabled}>
          {(provided, snapshot) => (
              <TableRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                  {...props}
              >
                  {props.children}
              </TableRow>
          )}
      </Draggable>
  )
}

export const DroppableComponent = (
  onDragEnd: (result, provided) => void) => (props) =>
{
  return (
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
              {(provided) => {
                  return (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                          {props.children}
                          {provided.placeholder}
                      </TableBody>
                  )
              }}
          </Droppable>
      </DragDropContext>
  )
}
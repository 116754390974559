import type { FC } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

export const LoginJwt: FC = () => {
  const mounted = useMounted();
  const { login } = useAuth() as any;
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
  const [openUserConditions, setOpenUserConditions] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      cgvAccepted: false,
      privacyAccepted: false,
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
      cgvAccepted: Yup.boolean().isTrue().required('You have to accept user conditions'),
      privacyAccepted: Yup.boolean().isTrue().required('You have to accept it'),
      policy: Yup.boolean()
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        await login(values.email, values.password);
        // dispatch(userSignIn(values.email, values.password));

        if (mounted.current) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        }
      } catch (err) {
        console.error(err);
        if (mounted.current) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: 3
        }}
      >
        <img src='/static/ipop-logo.png' width={200} alt='' />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: 3
        }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {t('login')}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Button
          color="primary"
          component={BrowserLink}
          to="/register"
          variant="text"
        >
          {t('signup')}
        </Button> */}
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <InputField
            autoFocus
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label={t('email')}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
            placeholder="ipop@tools.project"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <InputField
            error={Boolean(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label={t('password')}
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="password"
            value={formik.values.password}
            placeholder="Password"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormGroup>
            <FormControlLabel control={
              <Checkbox required checked={formik.values.cgvAccepted} onChange={() => formik.setFieldValue('cgvAccepted', !formik.values.cgvAccepted)} />
            } label={(
              <span>I have read and accepted <span style={{color: '#5e6af6'}} onClick={() => {formik.setFieldValue('cgvAccepted', !formik.values.cgvAccepted);setOpenUserConditions(true)}}>LICENCING AGREEMENT & GENERAL CONDITIONS OF USE (the Agreement)</span></span>
            )} />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormGroup>
            <FormControlLabel control={
              <Checkbox required checked={formik.values.privacyAccepted} onChange={() => formik.setFieldValue('privacyAccepted', !formik.values.privacyAccepted)} />
            } label={(
              <>
              <span>As the content and structure of this software is governed by copyright and other intellectual property rights, you undertake not to:</span>
              <ul>
                <li>copy or reproduce screenshots or features of this Web Platform;</li>
                <li>share features of this Web Platform with third parties with the intent of reproducing them in another software/software;</li>
                <li>reverse engineer, modify, decompile, or disassemble the software, directly or indirectly, in whole or in part.</li>
              </ul>
              <span>For the purpose of describing characteristics of your project, you are nevertheless authorized to share with your existing or potential stakeholders PDF documents produced by the software, such as the Opportunity Case.</span>
              </>
            )} />
          </FormGroup>
        </Grid>
        {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <LoadingButton
            color="primary"
            disabled={formik.isSubmitting}
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={loading}
          >
            {t('login')}
          </LoadingButton>
        </Grid>
        {/* <Grid
          item
          xs={12}
        >
          <Button
            color="primary"
            component={BrowserLink}
            to="/password-recovery"
            variant="text"
          >
            {t('forgot_password')}
          </Button>
        </Grid> */}
      </Grid>
      {/* <Alert
        severity="info"
        sx={{ mt: 3 }}
      >
        {t('you_can_use')}
        &nbsp;
        <strong>test@test.test</strong>
        &nbsp;
        {t('and_password')}
        &nbsp;
        <strong>test</strong>
      </Alert> */}
      <Dialog
        maxWidth="md"
        open={openUserConditions}
        onClose={() => setOpenUserConditions(false)}
      >
        <DialogTitle>
          LICENCING AGREEMENT & GENERAL CONDITIONS OF USE (the Agreement)
        </DialogTitle>
        <DialogContent>
          <p>
          THIS AGREEMENT IS A LEGAL AND BINDING CONTRACT BETWEEN THE USER AND THE OPERATOR  GOVERNING THE USER’S ACCESS TO AND USE OF THE OPERATOR’S SOFTWARE (AS FURTHER DEFINED BELOW, THE "WEB PLATFORM" or “”THE TOOLS”).<br /><br />
          BY USING THE WEB PLATFORM, THE USER INDICATES THE USER’S ACCEPTANCE OF THE TERMS, CONDITIONS AND LIMITATIONS OF THIS AGREEMENT.<br /><br />
          IF THE USER DOES NOT AGREE TO BE BOUND BY ALL OF THE TERMS, CONDITIONS AND LIMITATIONS OF THIS AGREEMENT, THE USER MUST PROMPTLY (A) DELETE THE WEB PLATFORM DATA FROM ANY AND ALL OF THE USER’S COMPUTERS ON WHICH IT IS INSTALLED AND DESTROY ALL COPIES OF THE WEB PLATFORM DATA IN THE USER’S POSSESSION AND ALL ACCOMPANYING MATERIALS, INCLUDING DOCUMENTATION, AND (B) CEASE ALL OF THE USER’S USE OF THE WEB PLATFORM.<br /><br />
          THIS AGREEMENT APPLIES TO THAT VERSION OF THE WEB PLATFORM THAT THE USER HAS LICENSED, WHETHER OBTAINED BY THE USER DIRECTLY FROM THE OPERATOR OR FROM AN AUTHORIZED OPERATOR LICENSEE OR RESELLER.  THE WEB PLATFORM IS LICENSED, NOT SOLD.  THE USER MAY USE ONLY THE VERSION OF THE WEB PLATFORM THAT THE USER HAS LICENSED FROM THE OPERATOR AND HAS PAID FOR, AND MUST USE IT STRICTLY IN ACCORDANCE WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT.
          </p>
          <p>
          <i>Last updated: 05/09/2022</i>
          </p>
          <h2>ARTICLE 1 – LEGAL NOTICE</h2>

          <p>
          The present IPOP TOOLS web platform, accessible at the following URL <a href="http://masschallenge.ipoptools.com">http://masschallenge.ipoptools.com</a> (the «Web Platform»), is published by :<br />
          Getratex SA, company with capital of CHF 500’000, registered in the Company Registrar of Canton of Geneva, Switzerland under number CHE-107.751.421, whose registered office is located at Cologny, Switzerland,<br />
          The individual VAT number of the Operator is: CHE-107.751.421.<br />
          The Web Platform is hosted by Amazon Web Services, based in Paris, France.<br />
          The Operator can be contacted at the following email address specify the contact email : tools@getratex.ch<br />
          </p>


          <h2>ARTICLE 2 – SERVICE DESCRIPTION</h2>

          <p>
          The Web Platform is made available to anyone accessing the site (the “User”) to use the IpOp Model for the pre-project stage analysis.<br />
          The Web Platform is not a merchant site and does not offer any remote commercial transactions.<br />
          The User remains responsible for the terms and consequences of its access to the Web Platform, particularly through the Internet. This access may involve the payment of fees to technical service providers such as, in particular, Internet service providers, which remain at its expense. In addition, the User must provide and be fully responsible for the equipment necessary to connect to the Web Platform.<br />
          The User acknowledges having verified that the computer configuration he uses is secure and in working order.<br />
          The information and services offered by the Web Platform can be for a fee or free of charge and accessible 24/7, except in the event of force majeure, computer failures, maintenance operations or problems related to telecommunications networks.<br />
          </p>

          <h2>ARTICLE 3 – INTELLECTUAL PROPERTY AND LICENSE TO USE THE WEB PLATFORM</h2>

          <ol type="a">
            <li>The Operator is the sole owner of all elements of the Web Platform, in particular and without limitation, all texts, files, animated or not, photographs, videos, logos, drawings, models, software, trademarks, visual identity, database, use of data, structure of reports, screen templates, structure of the Web Platform and any other elements of intellectual property and other data or information (hereinafter the “Elements”) that are protected by Swiss and international laws and regulations relating in particular to intellectual property.</li><br /><br />
            <li>Consequently, none of the Elements of the Web Platform may in whole or in part be modified, reproduced, copied, duplicated, sold, transmitted, published, communicated, distributed, broadcast, represented, stored, used, rented or otherwise operated, free of charge or for a fee, by a User or by a third party, regardless of the means and/or media used, whether known or unknown to date, without the express prior written authorization of the Operator on a case-by-case basis. Each User is solely responsible for any unauthorized use, operation and/or infringement of this Agreement.</li><br /><br />
            <li>The User is nevertheless authorized to freely publish or disclose the output results provided by Tools. Each publication or result bearing the Operator’s structure and presentation shall indicate the following copyright reference:<br />
“produced with the assistance of “IpOp Tools®”; <br />
© for structure: Management Boosters, Switzerland;<br />
© for content: ….” (=the User)<br />
 (“the Copyright Notice”)
</li><br /><br />
            <li>The User may not modify or make derivative works of the the Web Platform, the documentation therefore or any other component that may be included in the Software or provided with the Software, nor rent, lease or otherwise distribute the Software or the documentation therefore.</li><br /><br />
            <li>The User is not authorized to, directly or indirectly, adapt, show or transmit to third parties, use or copy intellectual property obtained from or developed by Management Boosters such as, the results of the Operator’s research, trademarks (registered or not), software, course or training material without the prior explicit written agreement of the Operator which agreement may be withheld or granted unconditionally at the Operator’s discretion. This commitment remains in force for the duration of this agreement and continues after its termination, expiry or cancellation.</li><br /><br />
            <li>The User may not reverse engineer, modify, decompile, or disassemble the Web Platform, directly or indirectly, in whole or in part.</li><br /><br />
            <li>In any event, all worldwide ownership of and all rights, title and interest in and to the Web Platform, and all copies and portions thereof, including without limitation, all copyrights, patent rights, trademark rights, trade secret rights, inventions and other proprietary rights therein and thereto, are and shall remain exclusively in the Operator.  The only rights the User acquires under this Agreement are the licenses set forth in this Agreement.</li><br /><br />
            <li>The Web Platform software and its functionalities must be treated as confidential information, in accordance with the provisions of article 8 below.</li><br /><br />
          </ol>


          <h2>ARTICLE 4 – LIABILITY AND GUARANTEE</h2>

          <p>
          The User acknowledges that the characteristics and constraints of the Internet do not guarantee the security, availability and integrity of data transmissions over the Internet. Thus, the Operator does not guarantee that the Web Platform and its services will function without interruption or operating error. In particular, operation may be temporarily interrupted due to maintenance, updates or technical improvements, or to evolve the content and/or their presentation.<br /><br />
          The Operator cannot be held responsible for the use that would be made of the Web Platform and its Elements by the Users in violation of this Agreement and for any direct or indirect damages that this use could cause to a User or to a third party. In particular, the Operator cannot be held responsible for false statements made by a User and for its behavior towards third parties. In the event that the Operator’s liability is sought as a result of such conduct by one of more Users, the latter undertake to guarantee the Operator against any condemnation against it and to reimburse the Operator for all costs, including attorneys' fees, incurred for his defense.
          </p>

          <h2>ARTICLE 5 – PERSONAL DATA</h2>

          <p>
          For more information about the Operator’s use of personal data, please carefully read the Privacy Policy (the “Policy”). This Policy can be consulted at any time on the Web Platform.
          </p>


          <h2>Article 6 – HYPERLINKS</h2>

          <p>
          The hypertext links available on the Web Platform may refer to third-party sites not published by the Operator. They are provided solely for the convenience of the User, in order to facilitate the use of resources available on the Internet. If the User uses these links, he will leave the Web Platform and will then agree to use the third-party sites at his own risk or as applicable in accordance with the conditions governing them.<br /><br />
          The User acknowledges that the Operator does not control or contribute in any way to the development of the terms of use and/or content applicable to or appearing on these third-party sites.<br /><br />
          As a result, the Operator cannot be held liable in any way for these hyperlinks.<br /><br />
          In addition, the User acknowledges that the Operator cannot endorse, guarantee or take over all or part of the terms of use and/or content of these third-party sites.<br /><br />
          The Web Platform may also contain promotional hyperlinks and/or advertising banners to third party sites not published by the Operator.<br /><br />
          The Operator invites the User to report to him any hyperlink present on the Web Platform that would allow to access a third party site offering content contrary to the laws and/or customs.<br /><br />
          The User may not use and/or insert a hyperlink pointing to the site without the prior written consent of the Operator on a case-by-case basis.
          </p>

          <h2>ARTICLE 7 – USE OF THE WEB PLATFORM</h2>

          <ol type="a">
            <li>
              <p>The User may not use the Web Platform to:</p>
              <ul>
                <li>(a) defame, abuse, harass, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others;</li>
                <li>(b) publish, post, distribute, disseminate or link to any:
                  <ul>
                    <li>(i) defamatory, infringing, or unlawful topic, name, material or information;</li>
                    <li>(ii) software or other material protected by intellectual property laws, copyright licenses, rights of privacy or publicity, or other proprietary rights, unless the User owns, controls such rights or has received all necessary consents for the User’s use of such software and other materials;</li>
                  </ul>
                </li>
                <li>(c) violate any applicable government laws or regulations.</li>
              </ul>
            </li><br /><br />
            <li>The User is solely responsible for the Users’s activities in using the Web Platform services including the activities of the User’s colleagues, contractors and all parties that the User allows to have access to the Web Platform.  The User is solely responsible for the contents, modification, management and/or deletion of any and all files and data used by the Users in conjunction with the Web Platform.  Further, the User is responsible to ensure that such files and data are not used in conjunction with the Web Platform in violation of any copyright or any other proprietary right of the Operator or any third party.  The Operator will not access the file itself or any data or information contained therein.</li><br /><br />
            <li>The Operator does not guarantee the accuracy, completeness, or usefulness of any content entered in the database by the Users (User Content), nor its merchantability or fitness for any particular purpose.  Under no circumstances will the Operator be liable for any loss or damage caused by the User’s reliance on any User Content. It is the User’s responsibility to evaluate the accuracy, completeness and/or usefulness of any User Content.</li><br /><br />
            <li>
              <p>If the Website contains bulletin board services, forums, communities, personal web pages, electronic mail postings and/or other message or communication facilities designed to enable the User to communicate with others or with a group (collectively, "Communication Services"), the User agrees to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service, and to comply in the User’s use of the Communication Services with all applicable laws and regulations and the terms and conditions of this Agreement, including, but not limited to, this article.  In addition, the User agrees that when using a Communication Service, the User shall not: </p>
              <ul>
                <li>(a) Advertise or offer to sell or buy any goods or services; </li>
                <li>(b) Restrict or inhibit any other User from using and enjoying its rights in the Communication Services; </li>
                <li>(c) Upload, post or otherwise transmit (including by linking to websites that so upload or post) files that contain viruses, spyware, worms, Trojan horses, or any other programs that may damage the operation of anyone's computer, are designed to program anyone's computer to perform any function which is unwanted by its owner, are designed to collect or transmit any information about a computer's owner without his or her knowledge, or are designed to perform any other illegal or illicit function;</li>
                <li>(d) Impersonate any person or entity, including the Operator, the Operator employees or other industry professionals; </li>
                <li>(e) Use racially, ethnically, or otherwise offensive language; </li>
                <li>(f) Violate any code of conduct or other guidelines that may be applicable for any particular Communication Service; or</li>
                <li>(g) Link the Communication Services to any unsolicited communication sent to any third party, or otherwise use or mention the Communications Services in connection with any such unsolicited communication.  The Operator is not a publisher of information and material posted by Users of the Communication Services ("User Content").  Accordingly, the Operator has no more editorial control over such content than does a library or newsstand.  Any opinions, advice, statements, services, offers, or other information or content expressed or made available as part of such User Content are those of the respective Users and not of the Operator.</li>
              </ul>
            </li>
          </ol>

          <h2>ARTICLE 8 - CONFIDENTIALITY</h2>

          <ol type="a">
            <li>The User data stored in the Web Platform database exclusively belongs to the User. The Operator agrees not to use nor to give access to third parties to the User’s data. The Operator will treat all information stored in the Web Platform database as confidential.</li><br /><br />
            <li>The User is responsible for safeguarding the confidentiality of the User’s password(s) and User name(s) selected or created by the User for use with the Web Platform, and for any use or misuse of the User’s Data resulting from any third party using a password or User name created by the User.  The Operator warrants and represents that the Web Platform software employs commercially reasonable security methods to prevent such misuse by unauthorized third parties. </li><br /><br />
            <li>Excluding the User’s authorized  use of the Web Platform for its own benefit,  each party agrees not to use, for other purposes or for the benefit of third parties, Confidential Information which has been received from the other party. Each party commits to only communicate such information to its employees and Users to the extent that it is necessary for them to do their work and provided that they are bound by the same confidentiality obligations. Each party remains the intellectual property owner of its own inventions. </li><br /><br />
            <li>"Confidential Information" covers all the information (including data stored in a database,  training methods, technical data or know-how, and in particular all information concerning research, products, services, Users, Operators, markets, software, inventions, projects under development, processes, drawings, methods, marketing, finance, trade method or any document) which has been explicitly identified in writing as  being  confidential. Confidential information provided orally must be confirmed in writing within 30 days after the oral communication.<br />
              Information will no longer be considered confidential as soon as it has become available to the public, has been freely obtained by the Recipient from another source who is not breaching any confidentiality obligation, or has been independently developed or discovered by the Recipient.   
            </li><br /><br />
            <li>All confidentiality obligations remain in force during this agreement and continues for 10 years after its cancellation, termination or expiry.</li><br /><br />
            <li>The parties will not share confidential information with their parent or sister companies or with their subsidiaries unless they are bound by a Non Disclosure Agreement also protecting the intellectual property of the disclosing party. Each party will take commercially reasonable steps to ensure that these provisions are also fully respected by its employees, Users and parent companies. </li>
          </ol>

          <h2>ARTICLE 9 – DISCLAIMER</h2>

          <ol type="a">
            <li>THE WEB PLATFORM AND ALL OF THE CONTENTS THEREOF ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND,WHETHER EXPRESSED, IMPLIED OR STATUTORY, AND THE OPERATOR HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY WITH RESPECT THE WEB PLATFORM AND ALL CONTENTS THEREOF, THE WEB PLATFORM, THE ACCOMPANYING DOCUMENTATION, AND ANY ACCOMPANYING SUPPORTING MATERIALS OR TRAINING.  THE USER EXPRESSLY ACKNOWLEDGES THAT THE WEB PLATFORM MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS.  NO EMPLOYEE, USER, AGENT, RESELLER, DEALER OR DISTRIBUTOR IS AUTHORIZED TO MODIFY THIS LIMITED WARRANTY OR TO MAKE ANY ADDITIONAL WARRANTIES.  SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO THE USER. THIS WARRANTY GIVES THE USER SPECIFIC LEGAL RIGHTS, AND THE USER MAY HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE OR JURISDICTION TO JURISDICTION.</li><br /><br />
            <li>NO LIABILITY FOR CONSEQUENTIAL DAMAGES. THE USER IS SOLELY RESPONSIBLE FOR INPUTS INTO THE SOFTWARE, VERIFYING THE RESULTS OF THE USE OF THE SOFTWARE AND HOW THE OUTPUT FROM THE SOFTWARE IS USED BY THE USER.  THE OPERATOR SHALL NOT BE RESPONSIBLE OR LIABLE TO THE USER OR ANY THIRD PARTY FOR THE RESULTS OR WORK PRODUCT OBTAINED FROM THE USER’S USE OF THE WEB PLATFORM.  IN NO EVENT SHALL THE OPERATOR BE LIABLE OR OBLIGATED TO THE USER IN ANY MANNER FOR ANY SPECIAL, NON-COMPENSATORY, CONSEQUENTIAL, INDIRECT, INCIDENTAL, STATUTORY OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, LOST DATA OR PROGRAMS, BUSINESS INTERRUPTIONS, LOST PROFITS AND LOST REVENUE, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT PRODUCT LIABILITY, OR OTHERWISE, EVEN IF THE OPERATOR IS AWARE OF THE POSSIBILITY OF ANY SUCH DAMAGES IN ADVANCE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO THE USER.  NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH IN THIS AGREEMENT, THE OPERATOR SHALL NOT BE LIABLE TO THE USER IN AN AMOUNT IN THE AGGREGATE GREATER THAN THE AMOUNT THAT RECEIVED BY THE OPERATOR FOR THE WEB PLATFORM LICENSED BY THE USER. THE LIMITATIONS SET FORTH ABOVE SHALL BE DEEMED TO APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDIES SET FORTH IN THIS AGREEMENT. THE PARTIES ACKNOWLEDGE AND AGREE THAT THEY HAVE FULLY CONSIDERED THE FOREGOING ALLOCATION OF RISK AND FIND IT EASONABLE, AND THAT THE FOREGOING LIMITATIONS ARE AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE PARTIES.</li>
          </ol>

          <h2>ARTICLE 10 – DURATION</h2>

          <p>This Agreement shall terminate immediately, without notice, in the event the User </p>

          <ol type="a">
            <li>fails to comply with any of the terms and conditions set forth in this Agreement, </li><br /><br />
            <li>being a business entity, the User ceases doing business, </li><br /><br />
            <li>the Operator does not receive in a timely manner (whether from the User or a from a reseller responsible to collect the User’s payment and remit it to the Operator) any payment owing to the Operator, or </li><br /><br />
            <li>the User ceases using the Web Platform service and notifies the Operator thereof.  </li>
          </ol>

          <p>Upon termination the User must immediately cease all of the User use of the Web Platform, and destroy all copies documentation in the User’s possession.</p>

          <p>This agreement is concluded for unlimited unless one of the parties terminates it in accordance with the following paragraph.</p>

          <p>Each party can terminate this agreement with immdiate notice. Such termination excludes refunds or any other claim for dammages or other compensation between the parties while fees due to the Operator must be paid for the complete duration of the selected offer until maturity. If the Operator terminates the agreement before an expiration date, the Operator will reimburse the User the portion of the prepaid fees corresponding to the time between the termination date and the expiration date. </p>

          <h2>ARTICLE 11 – GENERAL PROVISION</h2>

          <h3>ENTIRE AGREEMENT OF THE PARTIES</h3>

          <p>This Agreement constitutes a contract governing the relations between the User and the Operator. It contains all the rights and obligations of both the Operator and the User relating all of their interactions and to the use of the Web platform. If one or more stipulations of this Agreement are declared null and void pursuant to a law, a regulation or following a final decision of a competent court, the other stipulations will retain their full force and scope. Moreover, the fact that one of the parties to this Agreement does not invoke a breach by the other party of any of the provisions of these General Conditions of Use cannot be interpreted as a waiver of its rights. </p>

          <h3>MODIFICATIONS OF TERMS AND CONDITIONS OF USE</h3>

          <p>The Operator reserves the right to modify at any time and without notice the content of the Web Platform or the services available on it, and/or to temporarily or permanently cease to operate all or part of the Web Platform.</p>

          <p>In addition, the Operator reserves the right to modify at any time and without notice the location of the Web Platform on the Internet, as well as the content of this Agreement. The Operator will publish changes to this Agreement on the Web Platform so that the User knows what has been modified.</p>

          <p>The User is therefore obliged to refer to latest version of this Agreement before any use of the Web Platform. In case of hardware changes, the User will be informed by means of an email and a warning on the Web Platform before the implementation of the change. </p>

          <p>The User acknowledges that the Operator cannot be held liable in any way towards him or any third party for these modifications, suspensions or assignments.</p>

          <h3>COMPLAINTS & DISPUTES</h3>

          <p>In the event of a dispute, the User must first contact the company’s User service department at the following contact details: tools@getratex.ch</p>

          <p>Any dispute, controversy or claim arising out of or in relation to this Agreement which has not been dealt with in a satisfactory manner by the above User service, including the validity, invalidity, breach or termination thereof, shall be submitted to mediation in accordance with the Swiss Rules of Commercial Mediation of the Swiss Chambers of Commerce in force on the date when the request for mediation was submitted in accordance with these Rules.</p>

          <p>The seat of the mediation shall be Geneva. The mediation proceedings shall be conducted in English.</p>

          <p>If such dispute, controversy or claim has not been fully resolved by mediation within 60 days from the date when the mediator(s) has(have) been confirmed or appointed by the Chamber, it shall be settled by arbitration in fairness in accordance with the provisions for the Expedited Procedure and with the Swiss Rules of International Arbitration of the Swiss Chambers of Commerce in force on the date when the Notice of Arbitration was submitted in accordance with those Rules.</p>

          <p>The number of arbitrators shall be one.  This arbitrator must be competent to assess software and web platform issues.</p>

          <p>The seat of the arbitration shall be Geneva. The arbitral proceedings shall be conducted in English.</p>

          <h3>APPLICABLE LAW</h3>

          <p>These General Conditions of Use are governed, interpreted and applied in accordance with Swiss law.</p>

          <h3>USER ACCEPTANCE OF THE CONTENT OF THIS AGREEMENT</h3>

          <p>By registering on the Web Platform, the User confirms that he has carefully read this Agreement and has accepted its content. This acceptance is a contractual binding to this Agreement.</p>

          <p>This version of this Agreement currently posted on the Web Platform is the one applicable to all Users.</p>



        </DialogContent>
      </Dialog>
    </form>
  );
};

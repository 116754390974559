import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup,FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { DateField } from '../date-field';
import { decisions, moveConcernings } from '../../static-data';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSlice';


export const WizardCosting: FC = () => {
  const { t } = useTranslation();

  const {costing} = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();

  const [localeCosting, setLocaleCosting] = useState(costing);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [total, setTotal] = useState(0);

  const getTotal = () => {
    let tot = 0
    Object.keys(localeCosting).map((c) => {
      let amountPerUnit = '0';
      if (localeCosting[c]['amountPerUnit'] !== '') {
        amountPerUnit = localeCosting[c]['amountPerUnit'];
      }
      if (localeCosting[c]['units'] === null) {
        tot += parseInt(amountPerUnit, 10)
      } else {
        tot += parseInt(localeCosting[c]['units'], 10) * parseInt(amountPerUnit, 10)
      }
    });
    setTotal(tot);
  }

  const getSubTotal = (nb, unit) => {
    let amountPerUnit = '0';
    if (unit !== '') {
      amountPerUnit = unit;
    }
    if (nb === null) {
      return parseInt(amountPerUnit, 10)
    } else {
      return parseInt(nb, 10) * parseInt(amountPerUnit, 10)
    }
  }


  useEffect(() => {
    getTotal();
  }, [localeCosting]);

  const onChangeField = (element: string, field: string, value: string) => {
    const newCosting = JSON.parse(JSON.stringify(localeCosting));
    // const newCosting = {...localeCosting};
    newCosting[element][field] = value;
    setLocaleCosting(newCosting);
    dispatch(updateField({field: 'costing', value: newCosting}));
  };

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            <Table sx={{ minWidth: "100%", backgroundColor: 'background.default' }} size="small">
              <TableHead>
                <TableRow sx={{
                    borderBottomColor: 'background.paper',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                }}>
                  <TableCell sx={{
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    // borderTopRightRadius: idx === tableHeader.length-1 ? 10 : 0,
                    // borderBottomRightRadius: idx === tableHeader.length-1 ? 10 : 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    backgroundColor: 'background.paper',
                    color: 'primary.main',
                    py: 0
                  }}/>
                  <TableCell sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    backgroundColor: 'background.paper',
                    color: 'primary.main',
                    py: 0
                  }}>{t('units')}</TableCell>
                  <TableCell sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    backgroundColor: 'background.paper',
                    color: 'primary.main',
                    py: 0
                  }}>{t('amount_per_unit')}</TableCell>
                  <TableCell sx={{
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 1,
                    paddingBottom: 1,
                    backgroundColor: 'background.paper',
                    color: 'primary.main',
                    py: 0
                  }}>{t('total')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(localeCosting).map((c) => (
                  <TableRow sx={{
                    borderBottomColor: 'background.paper',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    '.MuiTableCell-root': {
                      borderBottomColor: 'background.paper',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                    },
                  }}>
                    <TableCell width={'25%'}>{t(c)}</TableCell>
                    <TableCell width={'25%'}>
                      {localeCosting[c]['units'] !== null ? (
                        <InputField
                          value={localeCosting[c]['units']}
                          onChange={(e) => onChangeField(c, 'units', e.target.value)}
                          // onBlur={onBlurField}
                          fullWidth
                          type="number"
                          InputProps={{
                            endAdornment: (<InputAdornment position="end">{c === 'space' ? 'm2' : 'hours'}&nbsp;&nbsp;</InputAdornment>),
                          }}
                        />
                      ) : (<></>)}
                    </TableCell>
                    <TableCell width={'25%'}>
                      {localeCosting[c]['amountPerUnit'] !== null ? (
                        <InputField
                          value={localeCosting[c]['amountPerUnit']}
                          onChange={(e) => onChangeField(c, 'amountPerUnit', e.target.value)}
                          // onBlur={onBlurField}
                          fullWidth
                          type="number"
                        />
                      ) : (<></>)}
                    </TableCell>
                    <TableCell align='right' width={'25%'}>
                      <b>{getSubTotal(localeCosting[c]['units'], localeCosting[c]['amountPerUnit'])} €</b>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" color="success">
                      {t('total')}
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">
                    <Typography variant="h6" color="success">
                      {total} €
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
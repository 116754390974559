import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";
import axios from "axios";

const initialState = {
  review3: null,
  factors: null,
  stakeholders: null,
  history: {
    object: null,
    field: null,
    data: [],
  },
  review3IsInitialized: false
};

export const review3Slice = createSlice({
  name: "review3",
  initialState,
  reducers: {
    getReview3: (state, action) => {
      state.review3 = action.payload.review_3;
      state.review3.confidence_indice = Math.min(action.payload.review_3.confidence_indice_factors, action.payload.review_3.confidence_indice_stakeholders);
      state.factors = action.payload.factors;
      state.stakeholders = action.payload.stakeholders;
      state.review3IsInitialized = true;
    },
    putReview3: (state, action) => {
      state.review3 = action.payload.review_3;
      state.review3.confidence_indice = Math.min(action.payload.review_3.confidence_indice_factors, action.payload.review_3.confidence_indice_stakeholders);
      state.review3 = action.payload;
      state.factors = action.payload.factors;
      state.stakeholders = action.payload.stakeholders;
    },
    putReview3Details: (state, action) => {
      const cus = action.payload;
      const newData = {...state.review3};
      Object.keys(cus).forEach((k) => {
        newData.review_3[k] = cus[k];
      });
      state.review3 = newData;
      state.review3.review_3.confidence_indice = Math.min(newData.review_3.confidence_indice_factors, newData.review_3.confidence_indice_stakeholders);
    },
  },
});

export const {
  getReview3,
  putReview3,
  putReview3Details
} = review3Slice.actions;

export default review3Slice.reducer;

export const review3GetAll = () => async (dispatch: AppDispatch) => {
    try {
      let factors_q = APIService.get("/factors")
      let stakeholders_q = APIService.get("/stakeholders")
      let review_3_q = APIService.get("/review-3")

      axios.all([factors_q, stakeholders_q, review_3_q]).then(axios.spread((...response) => {
        dispatch(putReview3({
          'factors': response[0].data.data.result,
          'stakeholders': response[1].data.data.result,
          'review_3': response[2].data.data.result
        }))
      })).catch(e => {
      })

    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review3PutElement =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put('/review-2/companies', data).then(
        (response) => {
          dispatch(putReview3(response.data.data.result));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review3PutDetails =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put(`/review-3`, data).then(
        (response) => {
          dispatch(putReview3Details(data));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review3UpdateOrder =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put("/factors/order", data).then(
        (response) => {
          // dispatch(getReview3());
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const getActiveElement = (pathname: string) => {
    const elements = pathname.split('/');

    if (elements.length > 2) {
        const parentType = `${elements[1].replace('-', '_')}_${elements[2].replace('-', '_')}`
        const parentId = elements.length > 3 ? elements[3] : null;

        return {parentType, parentId};
    } else {
        return {parentType: null, parentId: null};
    }
}
import { FC, useEffect } from 'react';
import { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Box,
  Avatar
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import type { Customer } from '../../types/customer';
import { InputField } from '../input-field';
import { AutocompleteField } from '../autocomplete-field';
import { customerPostOne, customerPutOne, customerDeleteOne } from '../../api/review-1/review-1-customer';
import { XCircle as XCircleIcon } from '../../icons/x-circle';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { concernings } from '../../static-data';
import { documentPost, resetDocumentStatus } from '../../slices/documentSlice';
import { RootState } from '../../store';
import { useTheme } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import { getActiveElement } from '../../utils/active-element';
import { ImageDropzone } from '../image-dropzone';
import filesize from 'filesize';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface DocumentDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DocumentDialog: FC<DocumentDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { documentIsLoading, documentStatus, documentNextAction } = useSelector((state: RootState) => state.document);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const activeElement = getActiveElement(location.pathname);

  useEffect(() => {
    console.log('store status', documentIsLoading);
    if (documentStatus === 'success') {
      toast.success('Document created');
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      setFile(null);
      setFilePreview(null);
      dispatch(resetDocumentStatus());
      if (documentNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: documentStatus });
      formik.setSubmitting(false);
    }
  }, [documentStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      isShared: true,
      title: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(3).max(255).required('Title is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleDrop = (newFiles: any): void => {
    console.log(newFiles);
    setFile(newFiles[0]);
    setFilePreview(URL.createObjectURL(newFiles[0]));
  };

  const handleDeleteFile = () => {
    setFile(null);
    setFilePreview(null);
  }

  const handleIsShared = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('isShared', event.target.checked);
  };

  const handleSubmitAndQuit = () => {
    console.log('Submit and quit');
    dispatch(documentPost('close', {...formik.values, ...activeElement, ...{size: file.size, type: file.type}}, file));
  };

  return (
    <Dialog
      // maxWidth="md"
      fullWidth
      onClose={() => {setOpenConfirmationDialog(true)}}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle sx={{ backgroundColor: "background.default" }}>
        {t('create_document')}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          my={2}
        >
          <Grid
            item
            xs={12}
          >
            { !file ? (
              <ImageDropzone
                onDrop={handleDrop}
                sx={{ height: '100%' }}
                // accept="image/jpeg, image/png"
              />
            ) :(
              <Grid container sx={{ backgroundColor: 'background.default', borderRadius: 1, padding: 1}} alignItems="center">
                <Grid item xs={2} sx={{ backgroundColor: 'background.paper', borderRadius: 1, padding: 1 }}>
                  {file.type.includes('image/') ? (
                    <img
                      alt=""
                      src={filePreview}
                      style={{ maxWidth: '100%' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFile as IconProp}
                      size="6x"
                      style={{ width: '100%'}} />
                  )}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={8} >
                  <Typography
                    variant='body1'
                  >
                   {file.name}
                  </Typography>
                  <Typography
                    variant='body2'
                  >
                   {`${file.type} - ${filesize(file.size)}`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => handleDeleteFile()}
                  >
                    <XCircleIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.title && formik.errors.title)}
              fullWidth
              helperText={formik.touched.title && formik.errors.title}
              label={t('title')}
              name="title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.title}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.description && formik.errors.description)}
              fullWidth
              helperText={formik.touched.description && formik.errors.description}
              label={t('description')}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              multiline
              rows={2}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <FormControlLabel control={<Switch checked={formik.values.isShared} onChange={handleIsShared} />} label={t('share_with_team')} />
            </FormGroup>
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ backgroundColor: "background.default" }}>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          size="small"
          // disabled={documentIsLoading}
          disabled={!(formik.isValid && formik.dirty) || !file || documentIsLoading}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
          fullWidth
        >
          {t('create')}
        </Button>
      </DialogActions>
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

DocumentDialog.defaultProps = {
  open: false
};

DocumentDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};

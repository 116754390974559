import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup,FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../../input-field';
import { DateField } from '../../date-field';
import { decisions, moveConcernings } from '../../../static-data';
import { projectGetMembers } from '../../../api/project';
import { StakeholderLeverTableDND } from './stakeholder-lever-dnd';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from "react-redux";
import { stakeholderPost, resetStakeholderStatus, updateField } from '../../../slices/stakeholderSlice';

export const WizardLever: FC = () => {
  const { t } = useTranslation();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { stakeholderLevers } = useSelector((state: RootState) => state.stakeholder);
  const dispatch = useDispatch();

  // DND CALLBACKS
  const onAddRow = (field: string) => {
    console.log(`new ${field} row`)
    let newData = JSON.parse(JSON.stringify(stakeholderLevers[field]));
    if (field === 'aspirations') {
      newData.push(
        {
          aspiration: {id: null, description: '', type: 1},
          level: 1,
          position: null
        }
      );
    } else {
      newData.push(
        {
          resistance: {id: null, description: ''},
          level: 1,
          position: null
        }
      );
    }
    
    dispatch(updateField({parentField: 'stakeholderLevers', field: field, value: newData}));
  }

  const handleChangeField = (index: number, name: string, field: string, value: string) => {
    let newData = JSON.parse(JSON.stringify(stakeholderLevers[field]));
    newData[index][name] = value;
    dispatch(updateField({parentField: 'stakeholderLevers', field: field, value: newData}));
  }

  const handleBlurField = (index: number, name: string, field: string, value: any) => {
    console.log('GFGJHGJH', index, name, field, value);
    let newData = JSON.parse(JSON.stringify(stakeholderLevers[field]));
    if (field === 'aspirations') {
      newData[index]['aspiration'] = value;
    } else {
      newData[index]['resistance'] = value;
    }
    dispatch(updateField({parentField: 'stakeholderLevers', field: field, value: newData}));
  }

  const handleOnReorder = (newOrderedData: any[], field: string) => {
    let newData = JSON.parse(JSON.stringify(stakeholderLevers[field]));
    newData = newOrderedData
    dispatch(updateField({parentField: 'stakeholderLevers', field: field, value: newData}));
  }

  const handleDeleteRow = (index: number, field: string) => {
    let newData = JSON.parse(JSON.stringify(stakeholderLevers[field]));
    newData.splice(index, 1);
    dispatch(updateField({parentField: 'stakeholderLevers', field: field, value: newData}));
  }

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default'}}
      // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
              <div>
                <Grid
                  container
                  spacing={2}
                >

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ marginBottom: 2}}
                    >
                      {'Aspirations'}
                    </Typography>
                    <StakeholderLeverTableDND 
                    field='aspirations'
                    // data={formik.values.levers?.aspirations}
                    data={stakeholderLevers.aspirations}
                    onChangeField={handleChangeField}
                    onAddRow={onAddRow}
                    onDeleteRow={handleDeleteRow}
                    onBlurField={handleBlurField}
                    onReorder={handleOnReorder} />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{ marginBottom: 2}}
                    >
                      {t('resistances')}
                    </Typography>
                    <StakeholderLeverTableDND 
                    field='resistances'
                    // data={formik.values.levers?.resistances}
                    data={stakeholderLevers.resistances}
                    onChangeField={handleChangeField}
                    onAddRow={onAddRow}
                    onDeleteRow={handleDeleteRow}
                    onBlurField={handleBlurField}
                    onReorder={handleOnReorder} />
                  </Grid>

                  {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <LoadingButton
                        color="primary"
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={loadingRequest}
                      >
                        {t('next_step')}
                      </LoadingButton>
                    </Box>
                  </Grid> */}
                </Grid>
              </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
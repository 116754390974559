import { useEffect, useState, useCallback } from "react";
import type { FC } from "react";
import Proptypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Checkbox,
  IconButton,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import gtm from "../../../lib/gtm";
import { InputField } from "../../input-field";
import { Scrollbar } from "../../scrollbar";
import { XCircle as XCircleIcon } from "../../../icons/x-circle";
import { ResourceUnavailable } from "../../resource-unavailable";
import { useTheme } from "@material-ui/core/styles";
import { DraggableComponent, DroppableComponent, reorder } from '../../dnd-table';
import { DNDInput } from './dnd-input';
import { useTranslation } from 'react-i18next';
import { PopoverList } from '../../popover-list';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';

interface StakeholderLeverTableDNDProps {
  field: string;
  data: any[];
  onChangeField: (index: number, name: string, field: string, value: any) => void;
  onAddRow: (field: string) => void;
  onDeleteRow: (index: number, field: string) => void;
  onBlurField: (index: number, name: string, field: string, value: any) => void;
  onReorder: (data: any[], field: string) => void;
}

// const tableHeader = ["", "is_constraint", "description", "level", ""];
const tableHeader = ["", "description", "importance", ""];

const alternativeStatus = {
  0: {
    label: '?',
    color: '#f3f4f7'
  },
  1: {
    label: 'not_provided',
    color: '#ec4c47'
  },
  2: {
    label: 'poor',
    color: '#ef6f6b'
  },
  3: {
    label: 'fair',
    color: '#736Be9'
  },
  4: {
    label: 'good',
    color: '#52bb8b',
  },
  5: {
    label: 'outstanding',
    color: '#27AB6E'
  }
}

const importanceStatus = {
  0: {
    label: 'unaddressed',
    color: '#8A8A8A',
    backgroundColor: 'rgba(138, 138, 138, 0.05)'
  },
  1: {
    label: 'not_concerned',
    color: '#8A8A8A',
    backgroundColor: 'rgba(138, 138, 138, 0.27)'
  },
  2: {
    label: 'not_important',
    color: '#AA9F3D',
    backgroundColor: 'rgba(255, 236, 68, 0.27)'
  },
  3: {
    label: 'important',
    color: '#D7AC3D',
    backgroundColor: 'rgba(255, 203, 68, 0.27)'
  },
  4: {
    label: 'very_important',
    color: '#FF7A00',
    backgroundColor: 'rgba(255, 122, 0, 0.27)'
  },
  5: {
    label: 'critical',
    color: '#FF2200',
    backgroundColor: 'rgba(255, 34, 0, 0.27)'
  }
}

export const StakeholderLeverTableDND: FC<StakeholderLeverTableDNDProps> = (props) => {
  const { t } = useTranslation();
  const { field, data, onChangeField, onAddRow, onDeleteRow, onBlurField, onReorder } = props;

  const [items, setItems] = useState(data);

  const theme = useTheme();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    setItems(data)
  }, [data]);

  const onDragEnd = (result) => {
    console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    // dropped outside the list
    if (!result.destination) {
        return
    }

    console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    const items = reorder(
      data,
      result.source.index,
      result.destination.index
    )

    console.log(items)

    onReorder(items, field);

    // this.setState({
    //     items
    // })
  }

  const onChangeLocale = useCallback((id, value, field) => {
    setItems(prevItems => prevItems.map((item, index) => {
      return index !== id ? item : { [field]: value }
    }))
  }, [])

  return (
          <Grid item xs={12}>
            <Scrollbar>
              <Table sx={{ minWidth: "100%", backgroundColor: 'background.default' }} size="small">
                <TableHead>
                  <TableRow sx={{
                    borderBottomColor: 'background.paper',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                  }}>
                    {tableHeader.map((h, idx) => (
                      <TableCell sx={{
                        borderTopLeftRadius: idx === 0 ? 10 : 0,
                        borderBottomLeftRadius: idx === 0 ? 10 : 0,
                        borderTopRightRadius: idx === tableHeader.length-1 ? 10 : 0,
                        borderBottomRightRadius: idx === tableHeader.length-1 ? 10 : 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        backgroundColor: 'background.paper',
                        color: 'primary.main',
                        py: 0
                      }}>{t(h)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody component={DroppableComponent(onDragEnd)}>
                  {items?.map((d, i) => (
                    <TableRow component={DraggableComponent(`item-${i}`, i)}
                      sx={{
                        borderBottomColor: 'background.paper',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        '.MuiTableCell-root': {
                          borderBottomColor: 'background.paper',
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                        },
                      }}
                    >
                      <TableCell width={1}>
                        <Typography variant="body1">
                          <b>{`#${i+1}`}</b>
                        </Typography>
                      </TableCell>
                      {/* <TableCell width={1}>
                        <Checkbox
                          checked={d.is_constraint}
                          onChange={() => onChangeField(i, 'is_constraint', field, !d.focus)}
                        />
                      </TableCell> */}
                      <TableCell>
                        {/* <DNDInput value={d.description} onBlurInput={(value) => onBlurField(i, 'description', field, value)} /> */}
                        <div style={{ width: '100%', backgroundColor: '#EEE', borderRadius: 5 }}>
                        <PopoverList type={field === 'aspirations' ? 'aspiration' : 'resistance'} element={field === 'aspirations' ? d.aspiration : d.resistance} onBlurInput={(element) => onBlurField(i, 'client_aspirations', field, element)} />
                        </div>
                      </TableCell>
                      <TableCell width={1} sx={{ padding: 1 }}>
                        <FormControl fullWidth >
                          <Select
                            value={d.level ? d.level : 0}
                            onChange={(e) => onChangeField(i, 'level', field, e.target.value)}
                            variant="standard"
                            disableUnderline
                            sx={{
                              backgroundColor: importanceStatus[d.level] && importanceStatus[d.level]['backgroundColor'],
                              color: importanceStatus[d.level] && importanceStatus[d.level]['color'],
                              py: 0.15, px: 1, borderRadius: 1, fontSize: 8, fontWeight: 500}}
                            IconComponent={(props) => (<ChevronDownIcon {...props} sx={{ color: `${importanceStatus[d.level]['color']} !important` }} />)}
                          >
                            {Object.keys(importanceStatus).map((importance) => (
                              <MenuItem value={importance}>{t(importanceStatus[importance]['label'])}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right" width={1}>
                        <IconButton
                          color="primary"
                          aria-label="Delete element"
                          onClick={() => onDeleteRow(i, field)}
                        >
                          <XCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
            {/* {(data?.length === 0 || data === null) && (
              <ResourceUnavailable
                sx={{
                  flexGrow: 1,
                  m: 2,
                }}
              />
            )} */}

            {/* <Divider style={{ marginTop: 20 }} /> */}

            {/* <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  onClick={() => onAddRow(field)}
                  style={{
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  + Element
                </Button>
              </Grid>
            </Grid> */}

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => onAddRow(field)}
                  style={{
                    backgroundColor: '#8F16C8',
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  + {t(`${field}_label`)}
                </Button>
              </Grid>
            </Grid>

          </Grid>
  );
};

StakeholderLeverTableDND.propTypes = {
  field: Proptypes.string.isRequired,
  data: Proptypes.array,
  onChangeField: Proptypes.func,
  onAddRow: Proptypes.func,
  onDeleteRow: Proptypes.func,
  onBlurField: Proptypes.func,
  onReorder: Proptypes.func
};

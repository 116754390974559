import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  moveDialogOpen: false,
  moveIsLoading: false,
  moveStatus: null,
  moveNextAction: null,
  moveId: null,
  name: "",
  comment: '',
  selectedForActionPlan: false,
  launchPlan: false,
  reduceUnknownPlan: false,
  decisionForActionPlan: 1,
  concerning: 1,
  collateralEffect: '',
  startDate: null,
  finishDate: null,
  estimatedCost: null,
  userIdeaId: null,
  descriptionOfTheMove: '',
  kpi: '',
  goalDate: null,
  descriptionOfTheGoal: '',
  costing: {
    workingCapital: { units: null, amountPerUnit: 0},
    equipmentCost: { units: null, amountPerUnit: 0},
    consultants: { units: null, amountPerUnit: 0},
    subcontractors: { units: null, amountPerUnit: 0},
    travelExpenses: { units: null, amountPerUnit: 0},
    team: { units: 0, amountPerUnit: 0},
    consultant: { units: 0, amountPerUnit: 0},
    it: { units: 0, amountPerUnit: 0},
    seniorManagement: { units: 0, amountPerUnit: 0},
    intermediateManagement: { units: 0, amountPerUnit: 0},
    engineering: { units: 0, amountPerUnit: 0},
    admin: { units: 0, amountPerUnit: 0},
    production: { units: 0, amountPerUnit: 0},
    other: { units: 0, amountPerUnit: 0},
    space: { units: 0, amountPerUnit: 0},
  },
  detailedImpact: null,
  isEditabled: true,
  isDuplicated: false,
};

export const move = createSlice({
  name: "move",
  initialState,
  reducers: {
    openMoveDialog: (state, action) => {
      // Reinitialize
      state.moveId = null;
      state.name = "" ;
      state.comment = '' ;
      state.selectedForActionPlan = false ;
      state.launchPlan = false ;
      state.reduceUnknownPlan = false ;
      state.decisionForActionPlan = 1 ;
      state.concerning = 1 ;
      state.collateralEffect = '' ;
      state.startDate = null ;
      state.finishDate = null ;
      state.estimatedCost = null ;
      state.userIdeaId = null ;
      state.descriptionOfTheMove = '' ;
      state.kpi = '' ;
      state.goalDate = null ;
      state.descriptionOfTheGoal = '' ;
      state.costing = {
        workingCapital: { units: null, amountPerUnit: 0},
        equipmentCost: { units: null, amountPerUnit: 0},
        consultants: { units: null, amountPerUnit: 0},
        subcontractors: { units: null, amountPerUnit: 0},
        travelExpenses: { units: null, amountPerUnit: 0},
        team: { units: 0, amountPerUnit: 0},
        consultant: { units: 0, amountPerUnit: 0},
        it: { units: 0, amountPerUnit: 0},
        seniorManagement: { units: 0, amountPerUnit: 0},
        intermediateManagement: { units: 0, amountPerUnit: 0},
        engineering: { units: 0, amountPerUnit: 0},
        admin: { units: 0, amountPerUnit: 0},
        production: { units: 0, amountPerUnit: 0},
        other: { units: 0, amountPerUnit: 0},
        space: { units: 0, amountPerUnit: 0},
      };
      state.detailedImpact = null;

      state.moveDialogOpen = true;
      if (action.payload.element.id) {
        // Element
        if (action.payload.isDuplicated === true) {
          state.moveId = null;
          state.name = `${action.payload.element.name} - copy`;
        } else {
          state.moveId = action.payload.element.id;
          state.name = action.payload.element.name;
        }
        state.comment = action.payload.element.comment;
        state.selectedForActionPlan = action.payload.element.selected_for_action_plan;
        state.launchPlan = action.payload.element.launch_plan;
        state.reduceUnknownPlan = action.payload.element.reduce_unknown_plan;
        state.decisionForActionPlan = action.payload.element.decision_for_action_plan;
        state.concerning = action.payload.element.concerning;
        state.collateralEffect = action.payload.element.collateral_effect;
        state.startDate = action.payload.element.start_date;
        state.finishDate = action.payload.element.finish_date;
        state.estimatedCost = action.payload.element.estimated_cost;
        state.userIdeaId = action.payload.element.user_idea_id;
        state.descriptionOfTheMove = action.payload.element.description_of_the_move;
        state.kpi = action.payload.element.kpi;
        state.goalDate = action.payload.element.goal_date;
        state.descriptionOfTheGoal = action.payload.element.description_of_the_goal;
        Object.assign(state.costing, action.payload.element.costing);
        state.detailedImpact = action.payload.element.detailed_impact;
        // Other
        state.isEditabled = action.payload.isEditabled;
        state.isDuplicated = action.payload.isDuplicated;
      }
    },
    closeMoveDialog: (state) => {
      state.moveDialogOpen = false;
    },
    loadingMove: (state) => {
      state.moveIsLoading = true;
    },
    resetMove: (state, action) => {
      state.moveIsLoading = false;
      state.moveStatus = 'success';
      state.moveNextAction = action.payload;
    },
    resetMoveStatus: (state) => {
      state.moveStatus = null;
    },
    updateField: (state, action) => {
      state[action.payload.field] = action.payload.value;
    }
  },
});

export const {
  openMoveDialog,
  closeMoveDialog,
  loadingMove,
  resetMove,
  resetMoveStatus,
  updateField
} = move.actions;

export default move.reducer;

export const movePost = (nextAction, data: any, id: any = null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingMove());
    if (id !== null && id !== undefined) {
      await APIService.put(`/moves/${id}`, data).then((response) => {
        dispatch(resetMove(nextAction));
      });
    } else {
      await APIService.post("/moves", data).then((response) => {
        dispatch(resetMove(nextAction));
      });
    }
  } catch (e) {
    // dispatch(setCustomerError());
  }
};


export const moveDelete = (id: string) => async (dispatch: AppDispatch) => {
  try {
    await APIService.delete(`/moves/${id}`).then((response) => {
    });
  } catch (e) {
  }
};
